<template>
  <div class="page-list-section">
    <div class="page-list-section__header">
      <NebulaIcon
        v-if="mode === 'EDIT'"
        class="page-list-section__grip"
        symbol-id="grip"
      />

      <template v-if="mode === 'EDIT'">
        <button
          ref="iconsDropdownToggleButton"
          class="page-list-section__icons-dropdown-toggle"
          :aria-label="$t('Select Section Icon')"
          @click="toggleIconsDropdown"
        >
          <NebulaIcon
            class="page-list-section__section-icon"
            :symbol-id="section.icon || 'information'"
          />
        </button>
        <IconPicker
          v-if="iconsDropdownHasActivated"
          class="page-list-section__icon-picker"
          :icons-dropdown-active="iconsDropdownActive"
          :selected-icon="section.icon"
          @icon-selected="updateIcon"
        />
      </template>
      <NebulaIcon
        v-else
        class="page-list-section__section-icon"
        :symbol-id="section.icon || 'information'"
      />

      <CollaborativeEditable
        class="page-list-section__forms-wrapper"
        :input-name="section.id"
        inset
      >
        <!-- Name form -->
        <form
          v-if="editingName"
          ref="changeNameForm"
          class="page-list-section__edit-name-form"
          @submit.prevent="confirmName"
        >
          <NebulaInput
            ref="editNameInput"
            class="page-list-section__name-input"
            :disabled="isLocked(section.id)"
            size="s"
            :text="tempName"
            type="text"
            @input="tempName = $event"
            @keydown.escape="confirmName"
          />
          <button
            ref="clearButton"
            :aria-label="$t('Clear section name')"
            type="button"
            :disabled="isLocked(section.id)"
            class="page-list-section__clear-name-button"
            @click="clearName"
          >
            <NebulaIcon
              symbol-id="x"
              size="s"
            />
          </button>
        </form>
        <template v-else>
          <button
            v-if="mode === 'EDIT'"
            :aria-label="$t('Edit section name')"
            class="page-list-section__edit-name-button"
            type="button"
            :disabled="isLocked(section.id)"
            @click="beginEditingName"
          >
            {{ section.name || $t('Section') }}
          </button>
          <span
            v-else
            class="page-list-section__edit-name-button"
          >
            {{ section.name || $t('Section') }}
          </span>
        </template>

        <!-- Pacing form -->
        <form
          v-if="editingPacing"
          ref="changePacingForm"
          class="page-list-section__edit-pacing-form"
          @submit.prevent="confirmPacing"
        >
          <NebulaInput
            ref="editPacingInput"
            class="page-list-section__pacing-input"
            :disabled="isLocked(section.id)"
            size="s"
            :text="(tempPacing || 0).toString()"
            type="number"
            @input="tempPacing = $event || '0'"
            @keydown.escape="confirmPacing"
          />
        </form>
        <template v-else>
          <button
            v-if="mode === 'EDIT'"
            :aria-label="$t('Edit section pacing')"
            class="page-list-section__edit-pacing-button"
            type="button"
            :disabled="isLocked(section.id)"
            @click="beginEditingPacing"
          >
            {{ $t('Pacing') }}:
            {{ formattedPacing }}
          </button>
          <span
            v-else
            class="page-list-section__edit-pacing-button"
          >
            {{ $t('Pacing') }}:
            {{ formattedPacing }}
          </span>
        </template>
      </CollaborativeEditable>
      <button
        v-if="mode === 'EDIT'"
        :aria-label="$t('Delete section')"
        class="page-list-section__delete-button"
        @click="removeSection"
      >
        <NebulaIcon
          symbol-id="x"
          size="s"
        />
      </button>
    </div>

    <slot />
  </div>
</template>

<script>
import { NebulaIcon, NebulaInput } from '@discoveryedu/nebula-components';
import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import CollaborativeEditable from '@/components/CollaborativeEditable.vue';
import IconPicker from '@/components/IconPicker.vue';
import { bus } from '@/lib/eventBus';
import { decodeHTML } from '@/lib/utils';
import {
  useEditorStore,
  useModalStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  name: 'PageListSection',
  components: {
    CollaborativeEditable,
    IconPicker,
    NebulaIcon,
    NebulaInput,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    sectionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editingName: false,
      editingPacing: false,
      iconsDropdownHasActivated: false,
      iconsDropdownActive: false,
      tempName: '',
      tempPacing: 0,
    };
  },
  computed: {
    ...mapState(useEditorStore, [
      'draft',
      'isLocked',
    ]),
    formattedPacing() {
      const pacing = parseInt(this.section.pacing_minutes || 0, 10);

      if (pacing < 10) return `0${pacing}`;

      return pacing;
    },
    section() {
      return get(this.draft, 'options.groups', [])
        .find((section) => section.id === this.sectionId)
        || {};
    },
  },
  beforeUnmount() {
    bus.off('document:mousedown', this.onBodyClick);
    bus.off('document:click', this.closeIconsDropdown);
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.DELETE_SECTION,
      types.UPDATE_SECTION,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    beginEditingName() {
      // Populate our temporary name and focus the input
      this.tempName = decodeHTML(this.section.name);
      this.editingName = true;
      bus.on('document:mousedown', this.onBodyClick);

      this.$nextTick(function focusInput() {
        if (this.$refs.editNameInput) {
          this.$refs.editNameInput.$el.focus();
        }
      });
    },
    beginEditingPacing() {
      // Populate our temporary pacing and focus the input
      this.tempPacing = this.section.pacing_minutes;
      this.editingPacing = true;
      bus.on('document:mousedown', this.onBodyClick);

      this.$nextTick(function focusInput() {
        if (this.$refs.editPacingInput) {
          this.$refs.editPacingInput.$el.focus();
        }
      });
    },
    clearName() {
      this.tempName = '';
      if (this.$refs.editNameInput) {
        this.$refs.editNameInput.$el.focus();
      }
    },
    closeIconsDropdown({ target }) {
      const inIconToggle = this.$refs.iconsDropdownToggleButton
        && this.$refs.iconsDropdownToggleButton.contains(target);
      const inIconInput = !!target.closest('.nebula-input');

      if (inIconToggle || inIconInput) return;
      this.iconsDropdownActive = false;
    },
    confirmName() {
      // Save the edited name
      this[types.UPDATE_SECTION]({
        id: this.section.id,
        name: this.tempName,
      });
      this.editingName = false;
      bus.off('document:mousedown', this.onBodyClick);
    },
    confirmPacing() {
      // Save the edited pacing
      this[types.UPDATE_SECTION]({
        id: this.section.id,
        pacing_minutes: this.tempPacing,
      });
      this.editingPacing = false;
      bus.off('document:mousedown', this.onBodyClick);
    },
    onBodyClick({ target }) {
      // Prevent false positives from elements that are immediately removed
      // from the DOM (e.g. the edit name button)
      if (!document.body.contains(target)) return;

      // If we've clicked away from an editing form, close and save it
      if (this.$refs.changeNameForm && !this.$refs.changeNameForm.contains(target)) {
        this.confirmName();
      }
      if (this.$refs.changePacingForm && !this.$refs.changePacingForm.contains(target)) {
        this.confirmPacing();
      }
    },
    toggleIconsDropdown() {
      this.iconsDropdownHasActivated = true;
      this.iconsDropdownActive = !this.iconsDropdownActive;
    },
    removeSection() {
      this[types.DELETE_SECTION](this.section);
    },
    updateIcon(icon) {
      this[types.UPDATE_SECTION]({
        id: this.section.id,
        icon,
      });
    },
  },
  watch: {
    iconsDropdownActive(isActive) {
      if (isActive) {
        bus.on('document:click', this.closeIconsDropdown);
      } else {
        bus.off('document:click', this.closeIconsDropdown);
      }
    },
  },
};
</script>

<style lang="stylus">
.page-list-section {
  background-color: $nebula-color-platform-interface-1100;
  min-height: 168px;
  padding: 2px 2px 0;

  &__header {
    align-items: center;
    border-bottom: 2px solid rgba($nebula-color-platform-interface-500, .3);
    display: flex;
    padding-bottom: $nebula-space-half;
  }

  &__forms-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__grip {
    fill: $nebula-color-white;
  }

  &__icons-dropdown-toggle {
    background-color: transparent;
    border: none;
    border-radius: $nebula-border-radius-badge-default;
    display: block;
    fill: $nebula-color-white;
    margin: 0 $nebula-space-half;
    padding: 0;
    transition: background-color $nebula-transition-default;

    &:hover, &:focus, &:active {
      background-color: rgba($nebula-color-white, .2);
    }
  }

  &__section-icon {
    display: block;
    fill: $nebula-color-white;
  }

  &__icon-picker {
    align-self: stretch;
    position: relative;

    .icon-picker__dropdown {
      left: -24px; // align with toggle button
      max-height: 160px; // align with bottom of slide drawer
      top: 100%;
    }

    .item-picker__item-search-results {
      color: $comet-text-color-primary-default; // default text color
      max-height: 116px; // align with bottom of slide drawer

      button.nebula-button {
        margin-inline-end: 0;
      }
    }
  }

  &__edit-name-form,
  &__edit-pacing-form {
    display: flex;
    position: relative;
  }

  &__name-input.nebula-input {
    margin: 0;
    padding: $nebula-space-1x;
    padding-inline-end: $nebula-space-3x;
  }

  &__pacing-input.nebula-input {
    margin: 0;
    padding: $nebula-space-1x;
    text-align: end;
    width: $nebula-space-8x;
  }

  &__delete-button {
    background: none;
    border: 0;
    border-radius: $nebula-border-radius-badge-default;
    margin: 0;
    padding: 6px;

    svg {
      display: block;
      fill: $nebula-color-white;
    }

    &:hover, &:focus, &:active {
      background-color: rgba($nebula-color-white, .2);
    }
  }

  &__clear-name-button {
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 6px;
    position: absolute;
    inset-inline-end: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      fill: $comet-color-interactive-default;
    }
  }

  &__edit-name-button {
    background: none;
    border: 0;
    border-radius: 0;
    cursor: text;
    display: block;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    padding: $nebula-space-half;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__edit-pacing-button {
    background: rgba($nebula-color-black, .5);
    border: 0;
    border-radius: $nebula-border-radius-badge-default;
    cursor: text;
    display: block;
    margin: 0;
    margin-inline-start: $nebula-space-half;
    max-width: 100%;
    overflow: hidden;
    padding: $nebula-space-half;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
