<template>
  <div
    :class="{ 'page-listing--has-overlay-icon': pageNotVisibleFullscreen || autoplayEnabled }"
    class="page-listing"
  >
    <div class="page-listing__preview">
      <NebulaIcon
        v-if="pageNotVisibleFullscreen"
        class="page-listing__overlay-icon"
        symbol-id="hide"
        size="s"
      />
      <NebulaIcon
        v-if="autoplayEnabled"
        class="page-listing__overlay-icon"
        symbol-id="play"
        size="s"
      />
      <div
        class="page-listing__preview-image-wrapper"
        :class="{
          'page-listing__preview-image-wrapper--selected': pageIsSelected,
        }"
      >
        <LoadingIndicator
          v-if="isAssetSlide && !asset"
          absolute
          transparent
        />
        <NebulaThumbnail
          v-else-if="asset && (!asset.type || asset.type.name !== 'TEI')"
          :asset-id="asset.id"
          image-size="small"
          :asset-type="asset.type && asset.type.name"
          class="page-listing__thumbnail"
        />
        <img
          v-else
          class="page-listing__preview-image"
          :src="thumbnailUrl"
          alt=""
        >
      </div>
      <NebulaBadge
        class="page-listing__page-number"
        :class="{
          'page-listing__page-number--current': page.id === currentPageId,
        }"
        :color="getStudioThumbnailAsset.badgeColor"
        size="s"
      >
        {{ index + 1 }}
        <span
          v-if="getStudioThumbnailAsset.updateBadge && draftCreatedByDe"
          class="page-listing__page-status-wrapper"
        >
          <NebulaTitleTooltip
            class="page-listing__page-status-tooltip"
            :class="`page-listing__toolbar-item--${getStudioThumbnailAsset.badgeColor}`"
            :text="$t(getStudioThumbnailAsset.type)"
          >
            <NebulaIcon
              v-if="getStudioThumbnailAsset.type === 'Error'"
              :symbol-id="getStudioThumbnailAsset.icon"
              size="s"
            />
            <LoadingIndicator
              v-if="getStudioThumbnailAsset.type === 'Processing'"
              transparent
              class="page-listing__page-loading-indicator"
            />
          </NebulaTitleTooltip>
        </span>
      </NebulaBadge>
    </div>
    <div class="page-listing__title-wrapper">
      <div class="page-listing__page-heading">
        <CollaborativeEditable
          :input-name="lockKey"
          :inset="true"
          class="page-listing__collaborative-editable"
        >
          <form
            v-if="editingTitle"
            ref="changeTitleForm"
            class="page-listing__edit-title-form"
            @submit.prevent="confirmTitle"
          >
            <NebulaInput
              ref="editTitleInput"
              class="page-listing__title-input"
              :disabled="isLocked(lockKey)"
              size="s"
              :text="tempTitle"
              type="text"
              @input="tempTitle = $event"
              @keydown.escape="confirmTitle"
            />
            <button
              ref="clearButton"
              :aria-label="boardLabelClear"
              type="button"
              :disabled="isLocked(lockKey)"
              class="page-listing__clear-title-button"
              @click="clearTitle"
            >
              <NebulaIcon
                symbol-id="x"
                size="s"
              />
            </button>
          </form>
          <template v-else>
            <button
              v-if="mode === 'EDIT'"
              :aria-label="boardLabelEdit"
              class="page-listing__edit-title-button"
              type="button"
              :disabled="isLocked(lockKey)"
              @click="beginEditingTitle"
            >
              {{ pageHeading }}
            </button>
            <span v-else>
              {{ pageHeading }}
            </span>
          </template>
        </CollaborativeEditable>
      </div>
      <comet-dropdown
        v-if="mode === 'EDIT'"
        ref="cometDropdown"
        menu-position-calculated
        class="page-listing__actions-dropdown"
        :button-aria-label="draftType === 'board'
          ? $t('Page Actions Menu')
          : $t('Slide Actions Menu')"
        button-class="page-listing__actions-dropdown-button"
        menu-class="page-listing__actions-dropdown-menu"
        @dropdown-open="toggleTopNavDropdownIsOpen"
      >
        <template #button>
          <NebulaIcon
            symbol-id="ellipses"
            size="s"
          />
        </template>
        <template #items>
          <div
            class="comet-list-group comet-dropdown-menu__list-group
              comet-list-group--no-hairlines"
          >
            <ul class="comet-list-group__list">
              <li
                class="comet-list-group__row"
                v-if="!(isEditingRestricted && isFullSlideTEI)"
              >
                <button
                  class="comet-list-group__row-anchor page-listing__dropdown-option"
                  @click="toggleFullscreenVisible"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="hide"
                  />
                  {{ fullscreenButtonLabel }}
                </button>
              </li>
              <li
                v-if="isAssetSlide && (assetGroup === 'video' || assetGroup === 'audio')"
                class="comet-list-group__row"
              >
                <button
                  class="comet-list-group__row-anchor page-listing__dropdown-option"
                  @click="toggleAutoplay"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="play"
                  />
                  {{ toggleAutoplayLabel }}
                </button>
              </li>
              <li
                class="comet-list-group__row"
                v-if="!(isEditingRestricted && isFullSlideTEI)"
              >
                <button
                  class="comet-list-group__row-anchor page-listing__dropdown-option"
                  @click="duplicatePage"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="copy"
                  />
                  {{ $t('Duplicate') }}
                </button>
              </li>
              <!-- Hiding option to copy page from the UI for now -->
              <li
                v-if="false"
                class="comet-list-group__row"
              >
                <button
                  class="comet-list-group__row-anchor page-listing__dropdown-option"
                  @click="copy"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="copy-to-clipboard"
                  />
                  {{ $t('Copy to Clipboard') }}
                </button>
              </li>
              <!-- Hiding option to turn grid off and on for now -->
              <li
                v-if="false"
                class="comet-list-group__row"
              >
                <button
                  :disabled="pageHasModules"
                  class="comet-list-group__row-anchor page-listing__dropdown-option"
                  @click="toggleGrid"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="studio-template"
                  />
                  {{ gridOffLabel }}
                </button>
              </li>
              <li
                v-if="pagesSorted.length > 1"
                class="comet-list-group__row"
              >
                <button
                  class="comet-list-group__row-anchor page-listing__dropdown-option"
                  @click="onDeletePages"
                >
                  <NebulaIcon
                    size="s"
                    symbol-id="trash"
                  />
                  {{ $t('Delete') }}
                </button>
              </li>
            </ul>
          </div>
        </template>
      </comet-dropdown>
    </div>
  </div>
</template>

<script>
import {
  NebulaBadge,
  NebulaIcon,
  NebulaInput,
  NebulaThumbnail,
  NebulaTitleTooltip,
} from '@discoveryedu/nebula-components';
import {
  get,
  set,
  cloneDeep,
} from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import LoadingIndicator from '@/components/LoadingIndicator.vue';
import CometDropdown from '@/components/ui-elements/CometDropdown.vue';
import { bus } from '@/lib/eventBus';
import * as types from '@/lib/constants/store';
import defaultThumbnail from '@/assets/blank_board.png';
import { decodeHTML, formatPageTitle, getPageThumbnail } from '@/lib/utils';
import CollaborativeEditable from '@/components/CollaborativeEditable.vue';
import copyPage from '@/mixins/copyPage';
import {
  useAssetStore,
  useBulkActionsStore,
  useEditorStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'PageListing',
  components: {
    CollaborativeEditable,
    CometDropdown,
    LoadingIndicator,
    NebulaBadge,
    NebulaIcon,
    NebulaInput,
    NebulaThumbnail,
    NebulaTitleTooltip,
  },
  mixins: [copyPage],
  props: {
    index: {
      type: Number,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  emits: ['deletePage'],
  data() {
    return {
      editingTitle: false,
      tempTitle: '',
      thumbnailUrl: null,
      isStudioThumbnail: null,
    };
  },
  computed: {
    ...mapState(useEditorStore, [
      'currentPageId',
      'draft',
      'draftHasTEIErrors',
      'draftType',
      'isLocked',
      'currentUserConnection',
      'pagesSorted',
      'gridIsDisabled',
      'draftIsDeCreated',
      'pagesById',
      'isEditingRestricted',
    ]),
    ...mapState(useBulkActionsStore, [
      'bulkActionPageIds',
    ]),
    ...mapState(useAssetStore, [
      'assets',
    ]),
    isFullSlideTEI() {
      const assetId = get(this, 'page.options.asset_id');

      return (
        assetId
        && this.draft.tei_page_asset_ids
        && this.draft.tei_page_asset_ids.includes(assetId)
      );
    },
    lockKey() {
      return `page_${this.page.id}`;
    },
    asset() {
      if (this.isAssetSlide) {
        return this.assets[this.page.options?.asset_id];
      }

      return null;
    },
    assetGroup() {
      return this.asset && this.asset.group;
    },
    autoplayEnabled() {
      return get(this, 'page.options.auto_play', false);
    },
    fullscreenButtonLabel() {
      if (this.pageNotVisibleFullscreen) {
        if (this.useBulkActions) {
          return this.draftType === 'board' ? this.$t('Unhide Pages') : this.$t('Unhide Slides');
        }

        return this.draftType === 'board' ? this.$t('Unhide Page') : this.$t('Unhide Slide');
      }

      if (this.useBulkActions) {
        return this.draftType === 'board' ? this.$t('Hide Pages') : this.$t('Hide Slides');
      }

      return this.draftType === 'board' ? this.$t('Hide Page') : this.$t('Hide Slide');
    },
    pageLabel() {
      return this.draftType === 'board' ? this.$t('page') : this.$t('slide');
    },
    pageHeading() {
      return formatPageTitle(this.page, this.index, this.draftType);
    },
    pageNotVisibleFullscreen() {
      return get(this, 'page.options.fullscreen_skip', false);
    },
    gridOffLabel() {
      return this.gridIsDisabled ? this.$t('Turn Grid On') : this.$t('Turn Grid Off');
    },
    boardLabelClear() {
      return this.draftType === 'board'
        ? this.$t('Clear page title')
        : this.$t('Clear slide title');
    },
    boardLabelEdit() {
      return this.draftType === 'board'
        ? this.$t('Edit page title')
        : this.$t('Edit slide title');
    },
    isAssetSlide() {
      return !!this.page.options?.asset_id;
    },
    toggleAutoplayLabel() {
      if (this.autoplayEnabled) {
        return this.$t('Turn Off Autoplay');
      }

      return this.$t('Turn On Autoplay');
    },
    pageHasModules() {
      return !!this.page?.modules?.length;
    },
    pageIsSelected() {
      return this.bulkActionPageIds.includes(this.page.id);
    },
    useBulkActions() {
      return this.pageIsSelected && this.bulkActionPageIds.length > 1;
    },
    draftCreatedByDe() {
      return this.mode === 'EDIT' && this.draftIsDeCreated;
    },
    getThumbnailMeta() {
      return get(this, 'page.thumbnail.thumbnail_meta');
    },
    getStudioThumbnailAsset() {
      const defaultAsset = { updateBadge: false, badgeColor: 'blue' };
      const progressAsset = { type: 'Processing', updateBadge: true, badgeColor: 'black' };
      const thumbnailMeta = this.getThumbnailMeta;
      const assetId = get(this, 'page.options.asset_id');

      // return default for full slide asset and non studio thumbnail
      if (!this.isStudioThumbnail || (!thumbnailMeta && assetId)) return defaultAsset;

      if (this.isStudioThumbnail && this.draftCreatedByDe) {
        // add process badge for new slide creation
        if (!thumbnailMeta) return progressAsset;
        // add error badge status
        if (thumbnailMeta.error_date) {
          const asset = {
            type: 'Error',
            icon: 'badge-warning',
            updateBadge: true,
            badgeColor: 'error',
          };
          return asset;
        }
        // add process badge status
        if ((thumbnailMeta.requested_date)
          && !(thumbnailMeta.processed_date || thumbnailMeta.error_date)) {
          return progressAsset;
        }
      }
      return defaultAsset;
    },
  },
  async mounted() {
    bus.on('document:mousedown', this.onBodyClick);

    const thumbnail = await getPageThumbnail(this.page, defaultThumbnail);
    this.thumbnailUrl = thumbnail.url;
    this.isStudioThumbnail = thumbnail.isStudioThumbnail;
  },
  beforeUnmount() {
    bus.off('document:mousedown', this.onBodyClick);
  },
  watch: {
    currentPageId() {
      this.editingTitle = false;
    },
    editingTitle() {
      if (this.mode === 'EDIT') {
        const key = this.lockKey;
        if (this.editingTitle) {
          this[types.UPDATE_CURRENTLY_EDITING]({ [key]: this.currentUserConnection?.id });
        } else {
          this[types.UPDATE_CURRENTLY_EDITING]({ [key]: null });
        }
      }
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.COPY_PAGES,
      types.DELETE_PAGES,
      types.UPDATE_PAGE,
      types.UPDATE_PAGES,
      types.UPDATE_PAGES_OPTIONS,
      types.SET_TOP_NAV_DROPDOWN_IS_OPEN,
      types.UPDATE_CURRENTLY_EDITING,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    ...mapActions(useBulkActionsStore, [
      types.SET_BULK_ACTION_PAGE_IDS,
    ]),
    beginEditingTitle() {
      // Populate our temporary title and focus the input
      this.tempTitle = decodeHTML(this.page.title);
      this.editingTitle = true;
      this.$nextTick(function focusInput() {
        if (this.$refs.editTitleInput) {
          this.$refs.editTitleInput.$el.focus();
        }
      });
    },
    confirmTitle() {
      // Save the edited title
      this[types.UPDATE_PAGES]([{
        id: this.page?.id,
        title: this.tempTitle,
      }]);
      this.editingTitle = false;
    },
    copy() {
      const pageIdsToCopy = this.useBulkActions
        ? this.bulkActionPageIds
        : [this.page.id];

      this.copyPagesToClipboard(pageIdsToCopy);
    },
    duplicatePage() {
      // If we have TEI errors, moving to a new page could overwrite changes. Confirm first
      if (this.mode === 'EDIT' && this.draftHasTEIErrors) {
        this[types.OPEN_MODAL]({
          type: 'TEIErrorModal',
          successCallback: () => {
            this[types.COPY_PAGES]([this.page]);
            if (this.$refs.cometDropdown) {
              this.$refs.cometDropdown.closeDropdown();
            }
          },
        });
        return;
      }

      const pageIdsToDuplicate = this.useBulkActions
        ? this.bulkActionPageIds
        : [this.page.id];

      const pagesToDuplicate = pageIdsToDuplicate
        .map((pageId) => this.pagesById[pageId])
        .filter((page) => page);

      if (pagesToDuplicate.length) {
        this[types.COPY_PAGES](pagesToDuplicate);
      }

      if (this.$refs.cometDropdown) {
        this.$refs.cometDropdown.closeDropdown();
      }
    },
    clearTitle() {
      this.tempTitle = '';
      if (this.$refs.editTitleInput) {
        this.$refs.editTitleInput.$el.focus();
      }
    },
    onBodyClick({ target }) {
      // Prevent false positives from elements that are immediately removed
      // from the DOM (e.g. the edit title button)
      if (!document.body.contains(target)) return;

      // If we've clicked away from the title input, close and save it
      if (this.$refs.changeTitleForm && !this.$refs.changeTitleForm.contains(target)) {
        this.confirmTitle();
      }
    },
    onDeletePages() {
      this.$emit('deletePage', this.page.id);
    },
    toggleAutoplay() {
      const pageIdsToUpdate = this.useBulkActions
        ? this.bulkActionPageIds
        : [this.page.id];

      const pagesToUpdate = pageIdsToUpdate.map((pageId) => (
        this.draft.pages.find((p) => p.id === pageId)
      )).filter((page) => page);

      const pageOptionsChanges = pagesToUpdate.map((page) => {
        const optionsChanges = { id: page.id, options: {} };
        // If the page is not an audio or video asset, don't
        // toggle its autoplay
        const pageAssetId = page.options && page.options.asset_id;
        const pageAsset = this.assets[pageAssetId];
        const pageCanAutoplay = pageAsset
          && (pageAsset.group === 'video' || pageAsset.group === 'audio');
        if (!pageCanAutoplay) return null;

        optionsChanges.options.auto_play = !this.autoplayEnabled;

        // Don't allow a page to be autoplay AND hidden
        const pageIsHidden = get(page, 'options.fullscreen_skip', false);
        if (pageIsHidden && !this.autoplayEnabled) {
          optionsChanges.options.fullscreen_skip = false;
        }

        return optionsChanges;
      }).filter((change) => change);

      this[types.UPDATE_PAGES_OPTIONS](pageOptionsChanges);
    },
    toggleFullscreenVisible() {
      const pageIdsToUpdate = this.useBulkActions
        ? this.bulkActionPageIds
        : [this.page.id];

      const pagesToUpdate = pageIdsToUpdate.map((pageId) => (
        this.draft.pages.find((p) => p.id === pageId)
      )).filter((page) => page);

      const pageOptionsChanges = pagesToUpdate.map((page) => {
        const optionsChanges = { id: page.id, options: {} };

        optionsChanges.options.fullscreen_skip = !this.pageNotVisibleFullscreen;

        // Don't allow a page to be autoplay AND hidden
        const pageHasAutoplayEnabled = get(page, 'options.auto_play', false);
        if (!this.pageNotVisibleFullscreen && pageHasAutoplayEnabled) {
          optionsChanges.options.auto_play = false;
        }

        return optionsChanges;
      });

      this[types.UPDATE_PAGES_OPTIONS](pageOptionsChanges);
    },
    toggleGrid() {
      const pageToUpdate = cloneDeep(this.page);
      set(pageToUpdate, 'options.grid_disabled', !this.gridIsDisabled);
      this[types.UPDATE_PAGE](pageToUpdate);
    },
    toggleTopNavDropdownIsOpen(isOpen) {
      this[types.SET_TOP_NAV_DROPDOWN_IS_OPEN]({ isOpen });
    },
  },
};
</script>

<style lang="stylus">
$preview-height = 84px;
$preview-width = 134px;
$page-heading-height = 30px; // prevent reflowing when title input is toggled

.page-listing {
  background-color: $nebula-color-platform-interface-1100;
  border-radius: 10px; // align with preview border radius
  padding: 2px 2px 0;

  // .page-listing__page is technically in PageList.vue
  &__page {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    list-style-type: none;
    margin: $nebula-space-1x 2px 0;

    &--draggable {
      cursor: grab;
    }
  }

  &__page-heading { // .page-listing__page-heading
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: $nebula-font-size-body-2;
    height: $page-heading-height;
    max-width: "calc(100% - %s)" % $nebula-space-3x;
    position: relative;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__collaborative-editable {
    border-radius: $nebula-border-radius-badge-default;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__edit-title-form { // .page-listing__edit-title-form
    display: flex;
    position: relative;
  }

  &__page-heading &__title-input.nebula-input {
    margin: 0;
    padding: $nebula-space-1x;
    padding-inline-end: $nebula-space-3x;
  }

  &__clear-title-button { // .page-listing__clear-title-button
    background: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 6px;
    position: absolute;
    inset-inline-end: 0;
    top: 50%;
    transform: translateY(-50%);

    svg {
      display: block;
      fill: $comet-color-interactive-default;
    }
  }

  &__edit-title-button { // .page-listing__edit-title-button
    background: none;
    border: 0;
    border-radius: 0;
    cursor: text;
    display: block;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    padding: $nebula-space-half;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__preview { // .page-listing__preview
    align-items: center;
    border-radius: $nebula-border-radius-badge-default;
    color: $comet-text-color-primary-default;
    display: flex;
    height: $preview-height;
    justify-content: center;
    margin-bottom: $nebula-space-1x;
    position: relative;
    width: $preview-width;
  }

  &--has-overlay-icon {
    .page-listing__preview-image {
      opacity: .65;
    }
  }

  &__overlay-icon {
    position: absolute;
    z-index: 1;
  }

  &__preview-image-wrapper {
    align-self: flex-end;
    border-radius: $nebula-border-radius-small;
    height: 100%;
    width: 100%;

    &--selected {
      outline: 2px solid $nebula-color-interactive-blue-300;
    }
  }

  &__preview-image {
    border-radius: $nebula-border-radius-small;
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__thumbnail {
    border-radius: $nebula-border-radius-small;
    height: 100%;

    .nebula-thumbnail__inner {
      border-radius: $nebula-border-radius-small;
    }
  }

  &__page-number.nebula-badge__container {
    .nebula-badge {
      border-radius: 6px; // align with wrapper curve
      bottom: 2px;
      inset-inline-end: 2px;
      position: absolute;
    }

    &:not(.page-listing__page-number--current) .nebula-badge {
      background-color: $nebula-color-platform-interface-1100;
    }
  }

  &__page-status-tooltip {
    .nebula-title-tooltip {
      inset-block-end: calc(125% + 8px);
      inset-inline-start: -100%;
    }
  }

  &__title-wrapper { // .page-listing__title-wrapper
    align-items: center;
    border-radius: 0 0 6px 6px;
    color: $nebula-color-white;
    display: flex;
    font-size: $nebula-font-size-caption;
    line-height: 24px;
    max-width: $preview-width;
    position: relative;
    text-align: center;
  }

  &__dropdown-option {
    background: none;
    border: 0;
    border-radius: 0;
    color: $nebula-color-black;
    font-family: $nebula-font-family-body;
    font-weight: 400;
    text-align: start;
    white-space: nowrap;

    svg {
      margin-inline-end: $nebula-space-1x;
    }

    &[disabled] {
      color: $nebula-color-platform-neutral-600;

      svg {
        fill: $nebula-color-platform-neutral-600;
      }
    }
  }

  .page-listing__actions-dropdown {
    width: $comet-size-icon-l;
  }

  .page-listing__actions-dropdown-button {
    background-color: transparent;
    display: block;
    fill: $nebula-color-white;
    height: $nebula-space-3x;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: $comet-size-icon-l;

    svg {
      display: block;
      margin-inline-start: $nebula-space-half;
    }

    &:hover, &:focus, &:active, &.comet-button--active, &.comet-button--hover {
      background-color: rgba($nebula-color-white, .2);
      box-shadow: none;
    }
  }

  html[dir="rtl"] & {
    .page-listing__actions-dropdown {
      position: absolute;
    }
  }
}

.page-listing__actions-dropdown-menu.comet-dropdown-menu {
  min-width: 150px;
  padding: $nebula-space-1x;

  span {
    text-align: start;
  }
}

.page-listing__page-status-wrapper {
  display: flex;
  align-items: center;
  padding-left: $nebula-space-1x;
  margin-left: $nebula-space-1x;
  border-left: 1px solid $nebula-color-white;

  svg {
    fill: $nebula-color-white;
    margin-right: 5px;
  }
}

.page-listing__page-loading-indicator {
  position: relative;

  .comet-spinner--medium .comet-spinner__icon {
    height 15px;
    width: 15px;
  }
}

.nebula-badge--error,
.nebula-badge--black {
  color: $nebula-color-white;
  opacity: 0.95;
}

.page-listing__page-number.nebula-badge__container > .nebula-badge.nebula-badge--error {
  background-color: $nebula-color-error;
}

.page-listing__page-number.nebula-badge__container > .nebula-badge.nebula-badge--black {
  background-color: $nebula-color-black;
}

.page-listing__toolbar-item--error {
  .nebula-title-tooltip__trigger:hover
  .nebula-title-tooltip {
    background-color: $nebula-color-error;

    span {
      background-color: $nebula-color-error;
    }
  }
}
</style>
