<template>
  <transition
    name="app-message-transition"
    enter-from-class="app-message__transition--enter-from"
    enter-active-class="app-message__transition--enter-active"
    leave-active-class="app-message__transition--leave-active"
    leave-to-class="app-message__transition--leave-to"
  >
    <div
      role="alert"
      class="app-message"
      :class="$route && `app-message--${$route.name}`"
    >
      <div
        v-if="heading"
        class="app-message__header"
      >
        <div class="app-message__header-left">
          <NebulaIcon
            v-if="icon"
            :symbol-id="icon"
            class="app-message__header-icon"
            :class="`app-message__header-icon--${type}`"
            size="m"
          />
          <div class="app-message__heading">
            {{ heading }}
          </div>
        </div>
        <button
          :aria-label="$t('Close')"
          class="nebula-button nebula-button--s nebula-button--icon-only
            app-message__header-close-button"
          :disabled="disableCancelButton"
          v-if="!hideHeaderCancelButton"
          @click="cancel"
        >
          <NebulaIcon
            size="m"
            symbol-id="x"
          />
        </button>
      </div>
      <div class="app-message__content">
        <slot />
      </div>
      <slot name="footer">
        <div
          v-if="showFooter"
          class="app-message__footer"
        >
          <button
            v-if="showActionButton"
            :aria-label="actionButtonText"
            class="nebula-button nebula-button--s nebula-button--fill"
            :class="{ 'nebula-button--disabled': disableActionButton }"
            :disabled="disableActionButton"
            @click="$emit('action-button-click')"
          >
            {{ actionButtonText }}
          </button>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { mapActions, mapState } from 'pinia';
import i18next from '@/lib/i18next';
import * as types from '@/lib/constants/store';
import {
  useMessageStore,
} from '@/stores';

export default {
  components: {
    NebulaIcon,
  },
  props: {
    disableCancelButton: {
      type: Boolean,
      default: false,
    },
    hideHeaderCancelButton: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'success',
    },
    duration: {
      type: Number,
      default: null,
    },
    actionButtonText: {
      type: String,
      default: i18next.t('Confirm'),
    },
    disableActionButton: {
      type: Boolean,
      default: false,
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['action-button-click'],
  mounted() {
    this.startTimeout();
  },
  watch: {
    appMessage() {
      this.startTimeout();
    },
  },
  computed: {
    ...mapState(useMessageStore, [
      'appMessage',
    ]),
    icon() {
      if (!this.showIcon) return null;
      if (this.type === 'error') return 'circle-x--filled';
      return 'circle-check--filled';
    },
    isEditRoute() {
      return ['edit', 'view'].includes(this.$route?.name);
    },
    showFooter() {
      return this.showActionButton;
    },
  },
  methods: {
    ...mapActions(useMessageStore, [
      types.SET_APP_MESSAGE,
    ]),
    startTimeout() {
      if (this.appMessage && this.duration) {
        const appMessageId = this.appMessage.id;
        setTimeout(() => {
          /*
            Only cancel if the app message that the timeout was started
            with is the same app message when the timeout expires.
          */
          if (appMessageId === this.appMessage.id) {
            this.cancel();
          }
        }, this.duration);
      }
    },
    cancel() {
      this[types.SET_APP_MESSAGE](null);
    },
  },
};
</script>

<style lang="stylus">
.app-message {
  background-color: $nebula-color-white;
  border-radius: $nebula-border-radius-small;
  box-shadow: $nebula-shadow-100;
  left: calc(50% + 108px);
  max-width: 450px;
  min-width: 250px;
  padding: $nebula-space-2x;
  position: fixed;
  top: $studio-navbar-height;
  transform: translateX(-50%);
  z-index: 900;

  &--edit, &--view {
    left: 50%;
    top: $studio-navbar-height-util-nav;
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    nebula-text-body-1();
    font-weight: bold;
  }

  &__header-left {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
  }

  &__header-icon {
    margin-right: $nebula-space-2x;

    &--success {
      fill: $nebula-color-success;
    }

    &--error {
      fill: $nebula-color-error;
    }
  }

  &__header-close-button.nebula-button {
    background-color: transparent;
    margin-left: $nebula-space-2x;
    padding: 0;

    &:hover, &:focus, &:active {
      background-color: transparent;
    }

    svg {
      fill: $nebula-color-interface-blue-400;
    }
  }

  &__content {
    padding-top: $nebula-space-2x;
  }

  &__footer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: $nebula-space-2x;
  }

  &__transition--enter-active {
    transition: $nebula-transition-default;
  }
  &__transition--leave-active {
    transition: $nebula-transition-default;
  }
  &__transition--enter-from,
  &__transition--leave-to {
    opacity: 0;
  }
}
</style>
