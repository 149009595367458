import { uniq } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import {
  useEditorStore,
  useToastStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  computed: {
    ...mapState(useEditorStore, [
      'draft',
      'draftType',
    ]),
  },
  methods: {
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    copyPagesToClipboard(pageIds) {
      // Copy a page's data to the clipboard. Firefox doesn't support
      // Clipboard.write() so we're saving it as a string

      const connectedModalIds = [];
      const pagesToCopy = [];

      pageIds.forEach((pageId) => {
        const page = this.draft.pages.find((p) => p.id === pageId);
        if (!page) return;

        pagesToCopy.push(page);

        [...page.modules, ...(page.notes || [])]
          .forEach((mod) => {
            // Find all connected modal ids
            if (mod.options?.action === 'window') {
              const modalId = mod.options.target_page;
              connectedModalIds.push(modalId);
            }
          });
      });

      const connectedModals = uniq(connectedModalIds).map((modalId) => (
        (this.draft.modals || []).find((modal) => modal.id === modalId)
      ));

      navigator.clipboard.writeText(
        JSON.stringify({
          copyType: 'pages',
          draftId: this.draft.id,
          draftType: this.draftType,
          pages: pagesToCopy, // this is always an array, even if we've only copied one page
          modals: connectedModals,
        }),
      ).then(() => {
        // These l10n terms don't have interpolation but they will still use
        // a plural term if we pass in `count`
        const message = this.draftType === 'board'
          ? this.$t('Page copied.', { count: pageIds.length })
          : this.$t('Slide copied.', { count: pageIds.length });

        this[types.SET_STUDIO_TOAST]({
          type: 'success',
          position: 'top',
          message,
        });
      });
    },
  },
};
