// Ensure comet styles are injected into the page first
import 'normalize.css/normalize.css';
import '@discoveryedu/nebula-core/dist/styles/fonts/proxima_nova.css';
import '@discoveryedu/nebula-core/dist/styles/fonts/proxima_vara.css';
import '@discoveryedu/nebula-core/dist/styles/fonts/mikado_chan.css';
import '@discoveryedu/comet-components/comet.css';
import '@discoveryedu/nebula-core/dist/styles/nebula_core.css';
import '@discoveryedu/nebula-components/dist/nebula-components.css';
import '@discoveryedu/mystery-components/dist/mystery-components.css';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'element-closest-polyfill';

import '@/lib/errorPolyfill';
import initialize from './initialize';
import {
  createAxiosClients,
  getDomains,
  getRegion,
  getEnv,
  getBuildTrackingDefaults,
  addRateLimiting,
} from './lib/api';

async function run() {
  // grab build defaults
  const buildInfo = await getBuildTrackingDefaults();

  const {
    apiClient,
    apiClientWithProgressBar,
    deApiClient,
    contentApiClient,
  } = createAxiosClients(window.location.hostname, buildInfo);

  // add a rate limiter to the content api
  addRateLimiting(contentApiClient);

  const initAppOptions = {
    api: apiClient,
    apiProgressBar: apiClientWithProgressBar,
    deApi: deApiClient,
    contentApi: contentApiClient,
    domains: getDomains(),
    env: getEnv(),
    region: getRegion(),
  };

  initialize(initAppOptions);
}

run();
