<template>
  <div
    class="page-list"
    :class="bulkDragging && 'page-list--bulk-dragging'"
  >
    <transition name="opacity-fade">
      <button
        class="page-list__floating-arrow-button page-list__floating-arrow-button--left"
        :aria-label="$t('scroll left')"
        v-if="showLeftScrollIndicator"
        @click="scrollCarousel('left')"
      >
        <NebulaIcon
          symbol-id="caret-left"
          size="s"
        />
      </button>
    </transition>
    <div
      class="page-list__transition-wrapper"
      ref="scrollContainer"
      @scroll="onScroll"
    >
      <Draggable
        :item-key="(pageOrSection) => pageOrSection.id || pageOrSection.sectionId"
        :scroll-sensitivity="200"
        :force-fallback="true"
        :list="[...groupedPages]"
        :animation="200"
        group="pages"
        :disabled="mode === 'VIEW'"
        filter=".page-listing__title-wrapper"
        :prevent-on-filter="false"
        tag="ul"
        :component-data="{
          type: 'transition-group',
          class: 'page-list__page-carousel',
        }"
        @start="onStart"
        @change="onChange"
        @end="onEnd"
      >
        <template #item="{ element: pageOrSection }">
          <li
            tabindex="0"
            ref="pageListing"
            class="page-listing__page"
            :class="{
              'page-listing__page--draggable': mode === 'EDIT',
              'page-listing__page--section': pageOrSection.isSection,
              'page-listing__page--selected': !pageOrSection.isSection
                && bulkActionPageIds.includes(pageOrSection.id),
            }"
            @keyup.delete="pageOrSection.isSection || onDeletePages(pageOrSection.id, $event)"
            @keyup.space="pageOrSection.isSection || selectPage(pageOrSection.id, $event)"
            @keyup.h="pageOrSection.isSection || hidePage(pageOrSection.id, $event)"
            @click="pageOrSection.isSection || selectPage(pageOrSection.id, $event)"
            @keydown.meta.c="pageOrSection.isSection || onCopy(pageOrSection.id, $event)"
            @keydown.ctrl.c="pageOrSection.isSection || onCopy(pageOrSection.id, $event)"
          >
            <PageListSection
              v-if="pageOrSection.isSection"
              :mode="mode"
              :section-id="pageOrSection.sectionId"
            >
              <div class="page-list__transition-wrapper">
                <Draggable
                  item-key="id"
                  :scroll-sensitivity="200"
                  :force-fallback="true"
                  :list="[...pageOrSection.pages]"
                  :animation="200"
                  :group="sectionGroupOptions"
                  :disabled="mode === 'VIEW'"
                  filter=".page-listing__title-wrapper"
                  :prevent-on-filter="false"
                  tag="ul"
                  :component-data="{
                    type: 'transition-group',
                    class: 'page-list__section-carousel',
                  }"
                  @start="onStart"
                  @change="onChange($event, pageOrSection)"
                  @end="onEnd"
                >
                  <template #item="{ element: page }">
                    <li
                      tabindex="0"
                      ref="pageListing"
                      class="page-listing__page"
                      :class="{
                        'page-listing__page--draggable': mode === 'EDIT',
                        'page-listing__page--selected': bulkActionPageIds.includes(page.id),
                      }"
                      @keyup.space="selectPage(page.id, $event)"
                      @keyup.delete="onDeletePages(page.id, $event)"
                      @keyup.h="hidePage(page.id, $event)"
                      @click="selectPage(page.id, $event)"
                      @keydown.meta.c="onCopy(page.id, $event)"
                      @keydown.ctrl.c="onCopy(page.id, $event)"
                    >
                      <PageListing
                        :index="page.index"
                        :mode="mode"
                        :page="page"
                        @delete-page="onDeletePages"
                      />
                      <BulkPageListingMessage
                        v-if="bulkActionPageIds.includes(page.id)"
                        class="page-list__bulk-message"
                      />
                    </li>
                  </template>
                </Draggable>
              </div>
            </PageListSection>
            <PageListing
              v-else
              :index="pageOrSection.index"
              :mode="mode"
              :page="pageOrSection"
              @delete-page="onDeletePages"
            />
            <BulkPageListingMessage
              v-if="!pageOrSection.isSection && bulkActionPageIds.includes(pageOrSection.id)"
              class="page-list__bulk-message"
            />
          </li>
        </template>
      </Draggable>
    </div>
    <transition name="opacity-fade">
      <button
        class="page-list__floating-arrow-button page-list__floating-arrow-button--right"
        :aria-label="$t('scroll right')"
        v-if="showRightScrollIndicator"
        @click="scrollCarousel('right')"
      >
        <NebulaIcon
          symbol-id="caret-right"
          size="s"
        />
      </button>
    </transition>
    <ConfirmDeleteLastTEI
      :confirm-func="runPageDeletion"
    />
  </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import Draggable from 'vuedraggable';
import { mapActions, mapState } from 'pinia';
import {
  first,
  get,
  last,
  throttle,
} from 'lodash-es';
import { bus } from '@/lib/eventBus';
import * as types from '@/lib/constants/store';
import BulkPageListingMessage from '@/components/navigation/BulkPageListingMessage.vue';
import ConfirmDeleteLastTEI from '@/components/modals/ConfirmDeleteLastTEI.vue';
import PageListing from '@/components/navigation/PageListing.vue';
import PageListSection from '@/components/navigation/PageListSection.vue';
import copyPage from '@/mixins/copyPage';
import {
  useAssessmentStore,
  useBulkActionsStore,
  useEditorStore,
  useModalStore,
  useToastStore,
} from '@/stores';

function getFirstSortIndex(pageOrSection) {
  return pageOrSection.isSection
    ? pageOrSection.pages[0].sort_index
    : pageOrSection.sort_index;
}

function getLastSortIndex(pageOrSection) {
  return pageOrSection.isSection
    ? last(pageOrSection.pages).sort_index
    : pageOrSection.sort_index;
}

export default {
  name: 'PageList',
  components: {
    BulkPageListingMessage,
    NebulaIcon,
    Draggable,
    PageListing,
    PageListSection,
    ConfirmDeleteLastTEI,
  },
  mixins: [copyPage],
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bulkDragging: false,
      showLeftScrollIndicator: false,
      showRightScrollIndicator: false,
      moved: null,
    };
  },
  computed: {
    ...mapState(useEditorStore, [
      'currentPageId',
      'draft',
      'currentPageIndex',
      'draftHasTEIErrors',
      'groupedPages',
      'pagesSorted',
      'isEditingRestricted',
    ]),
    ...mapState(useBulkActionsStore, [
      'bulkActionPageIds',
    ]),
    ...mapState(useAssessmentStore, [
      'unsubmittedAttempt',
      'teis',
    ]),
    pages() {
      // Adding this index is helpful when updating sort_index later in calculateSortIndexRange()
      return (this.pagesSorted || []).map((page, index) => ({
        ...page,
        index,
      }));
    },
    sectionGroupOptions() {
      return {
        name: 'pages',
        put(to, from, el) {
          // Do not allow sections to be dragged into other sections
          return !(el?.classList?.contains('page-listing__page--section'));
        },
      };
    },
  },
  mounted() {
    bus.on('window:resize', this.updateScrollIndicators);
  },
  beforeUnmount() {
    bus.off('window:resize', this.updateScrollIndicators);
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.DELETE_PAGES,
      types.MOVE_PAGES,
      types.UPDATE_PAGES_OPTIONS,
      types.VIEW_PAGE_BY_ID,
    ]),
    ...mapActions(useToastStore, [
      types.SET_STUDIO_TOAST,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    ...mapActions(useBulkActionsStore, [
      types.ADD_BULK_ACTION_PAGE_ID,
      types.REMOVE_BULK_ACTION_PAGE_ID,
      types.SET_BULK_ACTION_PAGE_IDS,
    ]),
    onCopy(pageId, e) {
      // If we're copying text in the title input, we shouldn't copy the
      // slide as well
      if (e.target.closest('input')) {
        return;
      }

      // Do nothing unless we're in edit mode
      if (this.mode !== 'EDIT') return;

      e.preventDefault();

      // If we're tabbing around outside the selected pages, just copy
      // this one page. Otherwise copy all selected pages
      const idIsInSelection = this.bulkActionPageIds.includes(pageId);

      const pageIdsToCopy = idIsInSelection ? this.bulkActionPageIds : [pageId];

      this.copyPagesToClipboard(pageIdsToCopy); // this method comes from the copyPage mixin
    },
    calculateSortIndexRange({ displacedPageOrSection, movedBackwards }) {
      let newSortIndexRange;

      if (displacedPageOrSection.index === 0
        || displacedPageOrSection.pages?.[0]?.index === 0) {
        // The page was moved to the beginning; find a number between
        // the displaced page and 0
        newSortIndexRange = [
          0,
          getFirstSortIndex(displacedPageOrSection),
        ];
      } else if (movedBackwards) {
        // The page was moved backwards; find a number between the displaced
        // page and its previous sibling
        const displacedPageOrSectionFirstIndex = displacedPageOrSection.index
          || displacedPageOrSection.pages?.[0]?.index;
        const previousPage = this.pages[displacedPageOrSectionFirstIndex - 1];

        newSortIndexRange = [
          getLastSortIndex(previousPage),
          getFirstSortIndex(displacedPageOrSection),
        ];
      } else if (displacedPageOrSection.index === this.pages.length - 1
        || last(displacedPageOrSection.pages)?.index === this.pages.length - 1) {
        // The page was moved to the end; just increment
        newSortIndexRange = [
          getLastSortIndex(displacedPageOrSection),
          getLastSortIndex(displacedPageOrSection) + 1,
        ];
      } else {
        // The page was moved forwards; find a number between the displaced
        // page and its next sibling
        const displacedPageOrSectionLastIndex = displacedPageOrSection.index
          || last(displacedPageOrSection.pages)?.index;
        const nextPage = this.pages[displacedPageOrSectionLastIndex + 1];

        newSortIndexRange = [
          getLastSortIndex(displacedPageOrSection),
          getFirstSortIndex(nextPage),
        ];
      }

      return newSortIndexRange;
    },
    async focus() {
      await this.$nextTick();
      this.$refs.pageListing[0].focus();
    },
    hidePage(pageId, e) {
      // Do nothing if we're editing the page name
      if (e?.target?.closest('input[type=text]')) return;

      // Do nothing unless we're in edit mode
      if (this.mode !== 'EDIT') return;

      // If we're tabbing around outside the selected pages, just hide
      // this one page. Otherwise hide all selected pages
      const useBulkActions = this.bulkActionPageIds.includes(pageId);

      const pageIdsToUpdate = useBulkActions
        ? this.bulkActionPageIds
        : [pageId];

      const pagesToUpdate = pageIdsToUpdate.map((id) => (
        this.draft.pages.find((p) => p.id === id)
      )).filter((page) => page);

      const newFullscreenSkipValue = !get(first(pagesToUpdate), 'options.fullscreen_skip');

      const pageOptionsChanges = pagesToUpdate.map((page) => {
        const optionsChanges = { id: page.id, options: {} };

        optionsChanges.options.fullscreen_skip = newFullscreenSkipValue;

        // Don't allow a page to be autoplay AND hidden
        const pageHasAutoplayEnabled = get(page, 'options.auto_play', false);
        if (newFullscreenSkipValue && pageHasAutoplayEnabled) {
          optionsChanges.options.auto_play = false;
        }

        return optionsChanges;
      });

      this[types.UPDATE_PAGES_OPTIONS](pageOptionsChanges);
    },
    runPageDeletion() {
      if (this.pageIdsToDelete) {
        this[types.DELETE_PAGES]({
          pageIds: this.pageIdsToDelete,
        });

        /*
          If we are deleting the selected pages, then we also need to
          clear the currently selected pages in the bulk actions store.
        */
        if (
          this.pageIdsToDelete.length === 1
          && this.bulkActionPageIds.includes(this.pageIdsToDelete[0])
        ) {
          this[types.SET_BULK_ACTION_PAGE_IDS]([]);
        }
      }
    },
    onDeletePages(pageId, e) {
      this.pageIdsToDelete = [];

      // Do nothing if we're editing the page name
      if (e?.target?.closest('input[type=text]')) return;

      // Do nothing unless we're in edit mode
      if (this.mode !== 'EDIT') return;

      // If we're tabbing around outside the selected pages, just delete
      // this one page. Otherwise delete all selected pages
      const idIsInSelection = this.bulkActionPageIds.includes(pageId);

      const pageIdsToUpdate = idIsInSelection ? this.bulkActionPageIds : [pageId];

      if (pageIdsToUpdate.length === this.draft.pages.length) {
        this[types.SET_STUDIO_TOAST]({
          type: 'error',
          position: 'top',
          message: this.draftType === 'board'
            ? this.$t('You cannot delete all pages.')
            : this.$t('You cannot delete all slides.'),
        });
        return;
      }

      // store list
      this.pageIdsToDelete = pageIdsToUpdate;

      // check to see if this is the last TEI slide being deleted
      if (this.isEditingRestricted && this.teis) {
        const totalTEISlides = this.draft.pages.reduce((assetIds, page) => {
          if (
            page.options?.asset_id
            && this.teis[page.options.asset_id]
          ) {
            assetIds.push(page.options.asset_id);
          }
          return assetIds;
        }, []);

        const teisToBeDeleted = this.pageIdsToDelete.reduce((pages, pageIdToDelete) => {
          const foundPage = this.draft.pages.find((page) => page.id === pageIdToDelete);
          if (
            foundPage
            && foundPage.options?.asset_id
            && this.teis[foundPage.options.asset_id]
          ) {
            pages.push(foundPage.options.asset_id);
          }
          return pages;
        }, []);

        if (totalTEISlides.length === teisToBeDeleted.length) {
          document.getElementById('confirm-delete-last-tei').showModal();
        } else {
          this.runPageDeletion(pageIdsToUpdate);
        }
      } else {
        this.runPageDeletion(pageIdsToUpdate);
      }
    },
    async selectPage(id, e = {}) {
      if (e.target?.closest('.page-listing__actions-dropdown')) {
        // If we've clicked in the options dropdown, no need to change the page
        return;
      }

      let nextPageId = id;

      if (e.shiftKey && this.mode === 'EDIT') {
        // We're trying to select a range of pages
        const pageIdx1 = this.currentPageIndex;
        const pageIdx2 = this.pages.findIndex((page) => page.id === id);

        // If we selected a page before the current page, we need to
        // swap the beginning and ending index
        const newSelectedPages = pageIdx1 < pageIdx2
          ? this.pages.slice(pageIdx1, pageIdx2 + 1)
          : this.pages.slice(pageIdx2, pageIdx1 + 1);

        const newSelectedPageIds = newSelectedPages.map((page) => page.id);

        nextPageId = id;

        this[types.SET_BULK_ACTION_PAGE_IDS](newSelectedPageIds);
      } else if ((e.ctrlKey || e.metaKey) && this.mode === 'EDIT') {
        // We're trying to select multiple pages
        if (this.bulkActionPageIds.includes(id) && id !== this.currentPageId) {
          // The page was already selected; deselect it but do not navigate pages
          nextPageId = null;
          this[types.REMOVE_BULK_ACTION_PAGE_ID](id);
        } else if (id === this.currentPageId && this.bulkActionPageIds.length > 1) {
          // We deselected the current page but have other selected pages.
          // Navigate to the first selected page
          const firstSelectedPage = this.pagesSorted
            .find((page) => this.bulkActionPageIds.includes(page.id)
              && page.id !== this.currentPageId);
          nextPageId = firstSelectedPage.id;

          this[types.REMOVE_BULK_ACTION_PAGE_ID](id);
        } else {
          // We selected a new page. Navigate to that page
          this[types.ADD_BULK_ACTION_PAGE_ID](this.currentPageId);
        }
      } else {
        // We're navigating pages normally. Clear out the selected pages
        this[types.SET_BULK_ACTION_PAGE_IDS]([]);
      }

      // If we're not actually navigating pages, exit
      if (!nextPageId) return;

      // moving an object triggers the click event, so we need to bypass after a move
      if (this.moved === null) {
        // If we have TEI errors, moving to a new page could overwrite changes. Confirm first
        if (this.mode === 'EDIT' && this.draftHasTEIErrors) {
          this[types.OPEN_MODAL]({
            type: 'TEIErrorModal',
            successCallback: () => {
              this[types.VIEW_PAGE_BY_ID](nextPageId);
            },
          });
          return;
        }

        // Confirm before navigating if we have an unsubmitted attempt
        if (this.unsubmittedAttempt) {
          this[types.OPEN_MODAL]({
            type: 'UnsavedAnswersModal',
            successCallback: () => {
              this[types.VIEW_PAGE_BY_ID](nextPageId);
            },
          });
          return;
        }

        await this[types.VIEW_PAGE_BY_ID](nextPageId);
      } else {
        // set the moved page, then clear the moved marker
        await this[types.VIEW_PAGE_BY_ID](this.moved);
        this.moved = null;
      }
    },
    onStart({ item }) {
      if (item.classList.contains('page-listing__page--selected') && this.bulkActionPageIds.length > 1) {
        this.bulkDragging = true;
      }
    },
    onEnd() {
      this.bulkDragging = false;
    },
    onChange({ added, moved }, section) {
      if (added) {
        // The 'added' param means that a page has changed sections, either
        // into a new section or out of a section altogether

        let displacedPageOrSection;
        let newSortIndexRange;
        if (section) {
          // If the page was added to the end of a section, the "displaced page"
          // might technically be outside of the section
          displacedPageOrSection = section.pages[added.newIndex]
            || this.pages[last(section.pages).index + 1];
        } else {
          displacedPageOrSection = this.groupedPages[added.newIndex];
        }

        if (!displacedPageOrSection) {
          // If we haven't "displaced" anything, move the page to the end
          newSortIndexRange = this.calculateSortIndexRange({
            displacedPageOrSection: last(this.pages),
            movedBackwards: false,
          });
        } else {
          newSortIndexRange = this.calculateSortIndexRange({
            displacedPageOrSection,
            movedBackwards: true,
          });
        }

        // Mark the moved page so it can be selected
        this.moved = added.element.id;

        const pageIdsToMove = this.bulkActionPageIds.includes(added.element.id)
          ? this.bulkActionPageIds
          : [added.element.id];

        // Ensure a unique sort_index for each page in the bulk action
        const sortIndexIncrement = (newSortIndexRange[1] - newSortIndexRange[0])
          / (pageIdsToMove.length + 1);

        this[types.MOVE_PAGES]({
          pages: pageIdsToMove.map((id, index) => ({
            id,
            sort_index: newSortIndexRange[0] + (sortIndexIncrement * (index + 1)),
            options: {
              group_id: section?.sectionId || undefined,
            },
          })),
        });
      }

      if (moved) {
        if (moved.element.isSection) {
          // We've moved a whole section to a new index. All its pages need to
          // be updated
          const displacedPageOrSection = this.groupedPages[moved.newIndex];
          const newSortIndexRange = this.calculateSortIndexRange({
            displacedPageOrSection,
            movedBackwards: moved.oldIndex - moved.newIndex > 0,
          });

          // Mark the first moved page so it can be selected
          this.moved = moved.element.pages[0].id;

          // Move all the pages in this section. First divide the "space" the pages
          // need to fit into (not including the ends of the range) into equal parts
          const sortIndexIncrement = (newSortIndexRange[1] - newSortIndexRange[0])
            / (moved.element.pages.length + 2);

          const pages = moved.element.pages.map((page, i) => ({
            // Move pages, putting each one in its sortIndex "slot"
            id: page.id,
            sort_index: newSortIndexRange[0] + (sortIndexIncrement * (i + 1)),
            options: {
              group_id: page.options?.group_id || undefined,
            },
          }));

          this[types.MOVE_PAGES]({ pages });
        } else {
          // We've moved a page to another place within the same section
          // or a page that was not in a section to another index
          // outside of a section
          const displacedPageOrSection = section
            ? section.pages[moved.newIndex]
            : this.groupedPages[moved.newIndex];
          let newSortIndexRange;

          if (!displacedPageOrSection) {
            // If we haven't "displaced" anything, move the page to the end
            newSortIndexRange = this.calculateSortIndexRange({
              displacedPageOrSection: last(this.pages),
              movedBackwards: false,
            });
          } else {
            newSortIndexRange = this.calculateSortIndexRange({
              displacedPageOrSection,
              movedBackwards: moved.oldIndex - moved.newIndex > 0,
            });
          }

          // Mark the moved page so it can be selected
          this.moved = moved.element.id;

          const pageIdsToMove = this.bulkActionPageIds.includes(moved.element.id)
            ? this.bulkActionPageIds
            : [moved.element.id];

          // Ensure a unique sort_index for each page in the bulk action
          const sortIndexIncrement = (newSortIndexRange[1] - newSortIndexRange[0])
            / (pageIdsToMove.length + 1);

          const movedPage = this.draft.pages.find((p) => p.id === moved.element.id);
          const newSectionId = get(movedPage, 'options.group_id');

          this[types.MOVE_PAGES]({
            pages: pageIdsToMove.map((id, index) => ({
              id,
              sort_index: newSortIndexRange[0] + (sortIndexIncrement * (index + 1)),
              options: {
                group_id: newSectionId || undefined,
              },
            })),
          });
        }
      }
    },
    updateScrollIndicators: throttle(function unthrottledUpdateScrollIndicators() {
      const { scrollContainer } = this.$refs;
      if (!scrollContainer) return;

      const { scrollLeft } = scrollContainer;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

      this.showLeftScrollIndicator = scrollLeft > 0;
      this.showRightScrollIndicator = scrollLeft < maxScrollLeft;
    }, 100),
    onScroll: throttle(function throttleOnScroll() {
      bus.emit('pageList:scroll');
      this.updateScrollIndicators();
    }, 10),
    scrollCarousel(direction) {
      const { scrollContainer } = this.$refs;
      if (!scrollContainer) return;

      const { scrollLeft } = scrollContainer;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const distance = scrollContainer.clientWidth - 80;
      let newScrollLeft;

      // Calculate position to scroll to
      if (direction === 'left') {
        newScrollLeft = Math.max(scrollLeft - distance, 0);
      } else {
        newScrollLeft = Math.min(scrollLeft + distance, maxScrollLeft);
      }

      // Smooth scroll if possible
      if (scrollContainer.scrollTo) {
        scrollContainer.scrollTo({
          behavior: 'smooth',
          left: newScrollLeft,
        });
      } else {
        scrollContainer.scrollLeft = newScrollLeft;
      }
    },
  },
  watch: {
    expanded(expanded) {
      if (expanded) {
        this.$nextTick(() => {
          this.updateScrollIndicators();
        });
      }
    },
    'pages.length': function onPagesLengthUpdated() {
      this.$nextTick(() => {
        this.updateScrollIndicators();
      });
    },
  },
};
</script>

<style lang="stylus">
.page-list {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  padding-top: $nebula-space-2x;
  position: relative;

  &__floating-arrow-button { // .page-list__floating-arrow-button
    background-color: rgba($comet-color-black, $comet-background-color-transparency-over-image);
    border: 0;
    border-radius: $nebula-border-radius-badge-default;
    margin: 0;
    padding: $nebula-space-1x;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background $nebula-transition-fade-in, opacity $nebula-transition-fade-in;
    z-index: 10;

    &:hover, &:focus {
      background-color: $comet-color-black;
    }

    &--left { // .page-list__floating-arrow-button--left
      inset-inline-start: $nebula-space-half;
    }

    &--right { // .page-listing__floating-arrow-button--right
      inset-inline-end: $nebula-space-half;
    }

    svg {
      display: block;
      fill: $nebula-color-white;
    }
  }

  &__transition-wrapper { // .page-list__transition-wrapper
    overflow: auto;
    width: 100%;
  }

  &__page-carousel {
    display: flex;
    margin-block: 0;
    margin-inline: $nebula-space-2x 0;
    padding: 0 0 24px;
  }

  &__section-carousel {
    display: flex;
    margin: 0;
    overflow-y: hidden;
    padding: 0;
  }

  &__bulk-message {
    display: none;
  }

  &--bulk-dragging .page-listing__page--selected {
    // During bulk drag, we only need to drag one element around.
    // Hide the others
    &:not(.sortable-ghost):not(.sortable-drag) {
      display: none;
    }

    // During bulk drag, hide the normal page preview and show the
    // bulk text like "3 Slides" instead. This is toggled in css
    // since Vue can't update the DOM before vue-draggable makes
    // its dragging copy that follows the mouse around
    &.sortable-ghost, &.sortable-drag {
      .page-listing {
        display: none;
      }

      .page-list__bulk-message {
        display: block;
      }
    }
  }
}

// Transitions
.opacity-fade {
  &-enter-active, &-leave-active {
    opacity: 1;
  }
  &-enter-from, &-leave-to {
    opacity: 0;
  }
}
</style>
