<template>
  <NebulaDialog
    size="m"
    id="confirm-delete-last-tei"
    ref="confirmDeleteModal"
  >
    <template #header>
      <h1>{{ $t('Are you sure you want to delete this last assessment slide?') }}</h1>
    </template>
    <template #content>
      <div
        class="confirm-delete-last-tei-modal__content-container"
      >
        {{ message }}
      </div>
    </template>
    <template #actions-row="{ closeDialog }">
      <NebulaButton
        id="confirm-delete-last-tei-modal-button-continue"
        @click="closeDialog"
        type="flat"
        :text="$t('Cancel')"
        size="l"
      />
      <NebulaButton
        id="confirm-delete-last-tei-modal-button-close"
        @click="onContinue"
        size="l"
        tabindex="1"
        :text="$t('Delete Assessment Slides')"
      />
    </template>
  </NebulaDialog>
</template>

<script>
import {
  NebulaButton,
  NebulaDialog,
} from '@discoveryedu/nebula-components';

export default {
  name: 'ConfirmDeleteLastTEI',
  components: {
    NebulaDialog,
    NebulaButton,
  },
  props: {
    confirmFunc: {
      type: Function,
      default: null,
    },
  },
  computed: {
    message() {
      return this.$t(
        'By deleting all assessment slides, this lesson won\'t populate in the Results Dashboard',
      );
    },
  },
  methods: {
    async onContinue() {
      if (this.confirmFunc) {
        this.confirmFunc();
        this.$refs.confirmDeleteModal.closeDialog();
      }
    },
  },
};
</script>

<style lang="stylus">
.confirm-assign {
  &__button-row.comet-modal__button-row {
    display: flex;
    justify-content: space-between;
  }

  &__cancel-button.comet-button {
    margin-left: 0;
  }
}
</style>
