<template>
  <BaseModal
    :id="id"
    :disable-confirm-button="deleting"
    :disable-cancel-button="deleting"
    :close-on-backdrop-click="!deleting"
    :is-wide="false"
    :on-confirm="confirmDelete"
    :heading="$t('Move to Trash')"
    :confirm-text="$t('Yes, Move to Trash')"
    :cancel-text="$t('Cancel')"
  >
    <CometBlockMessage
      block-message-type="error"
      v-if="error"
      role="alert"
    >
      <p>{{ error }}</p>
    </CometBlockMessage>
    <p>
      <!-- eslint-disable-next-line max-len -->
      {{ $t('It will be removed from “My Content” so you and others you’ve shared with can no longer access it.') }}
    </p>
    <p>
      {{ $t('Are you sure you want to move this?') }}
    </p>
  </BaseModal>
</template>

<script>
import { get } from 'lodash-es';
import { mapActions } from 'pinia';
import CometBlockMessage from '@/components/ui-elements/CometBlockMessage.vue';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useEditorStore,
  useMessageStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'ConfirmDeleteBoard',
  components: {
    BaseModal,
    CometBlockMessage,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      error: false,
      deleting: false,
    };
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    ...mapActions(useEditorStore, [
      types.DELETE_DRAFT,
    ]),
    ...mapActions(useMessageStore, [
      types.SET_APP_MESSAGE,
    ]),
    async confirmDelete() {
      try {
        this.deleting = true;
        this.error = null;

        await this[types.DELETE_DRAFT](
          {
            id: this.options.draft.id,
            published: !!this.options.draft.metadata.published_on,
          },
        );

        this[types.CLOSE_MODAL]({ id: this.id });

        if (this.options.successCallback) {
          await this.options.successCallback();
        }

        this.$nextTick(() => {
          this[types.SET_APP_MESSAGE]({
            type: 'MovedToTrashMessage',
            draft: this.options.draft,
          });
        });
      } catch (e) {
        this.error = get(e, 'response.data.meta.message', e);
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>
