import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import { omit, isEqual } from 'lodash-es';
import { bus } from '@/lib/eventBus';
import i18next from '@/lib/i18next';
import {
  useAppStore,
  useConfirmationStore,
  useEditorStore,
  useErrorStore,
  useAssessmentStore,
  useMessageStore,
} from '@/stores';
import * as types from '@/lib/constants/store';
import { getLocalData } from '@/lib/localData';

const ActivityTemplates = () => import(/* webpackChunkName: "activity-templates" */ '../views/ActivityTemplates.vue');
const CreateWithStudio = () => import(/* webpackChunkName: "create-with-studio" */ '../views/CreateWithStudio.vue');
const PrintPreview = () => import(/* webpackChunkName: "print-preview" */ '../views/PrintPreview.vue');
const StudioHome = () => import(/* webpackChunkName: "home" */ '../views/StudioHome.vue');
const StudioEditor = () => import(/* webpackChunkName: "editor" */ '../views/StudioEditor.vue');

const routes = [
  // Redirects from retired routes
  {
    path: '/creations/published',
    redirect: {
      name: 'home',
      query: {
        filter: 'shared',
      },
    },
  },

  // In-use routes
  {
    path: '/',
    name: 'home',
    component: StudioHome,
    props: {
      sidebarFilter: 'MY_STUDIO',
    },
    meta: {
      title: () => i18next.t('My Studio'),
    },
  },
  {
    path: '/shared-with-me',
    name: 'shared-with-me',
    component: StudioHome,
    props: {
      sidebarFilter: 'SHARED_WITH_ME',
    },
    meta: {
      title: () => i18next.t('Shared with Me'),
    },
  },
  {
    path: '/creations/assigned',
    name: 'assigned',
    component: StudioHome,
    props: {
      sidebarFilter: 'ASSIGNED',
    },
    meta: {
      title: () => i18next.t('Assigned Creations'),
    },
  },
  {
    path: '/creations/archived',
    name: 'archived',
    component: StudioHome,
    props: {
      sidebarFilter: 'ARCHIVED',
    },
    meta: {
      title: () => i18next.t('Archived Creations'),
    },
  },
  {
    path: '/trash',
    name: 'trash',
    component: StudioHome,
    props: {
      sidebarFilter: 'TRASH',
    },
    meta: {
      title: () => i18next.t('Trash'),
    },
  },
  {
    path: '/activity-templates',
    name: 'activity-templates',
    component: ActivityTemplates,
    meta: {
      title: () => i18next.t('Activity Templates'),
    },
  },
  {
    path: '/print-preview',
    name: 'print-preview',
    component: PrintPreview,
    query: {
      id: ':id',
      type: ':type',
    },
    meta: {
      title: () => i18next.t('Print Preview'),
    },
  },
  {
    path: '/edit',
    name: 'edit',
    query: {
      id: ':id',
      pageId: ':page_id',
      type: ':type',
      source_id: ':source_id',
    },
    props: () => {
      const hasLocalData = !!getLocalData();
      return {
        mode: hasLocalData ? 'VIEW' : 'EDIT',
      };
    },
    component: StudioEditor,
    meta: {
      title: () => i18next.t('Draft'),
    },
  },
  {
    path: '/view',
    name: 'view',
    query: {
      id: ':id',
      type: ':type',
      pageId: ':page_id',
      enableHighlight: ':enable_highlight',
    },
    props: (route) => ({
      mode: 'VIEW',
      enableHighlight: !!route.query.enableHighlight,
      progressTrackingData: {
        source: 'studio',
        enabled: true,
      },
    }),
    meta: {
      title: () => i18next.t('Creation'),
    },
    component: () => import(/* webpackChunkName: "editor" */ '../views/StudioEditor.vue'),
  },
  {
    path: '/create',
    name: 'create',
    component: CreateWithStudio,
    meta: {
      title: () => i18next.t('Create'),
    },
  },
];

// These are functions so they are not evaluated on pageload before i18next language is set
const unsavedChangesMessage = () => i18next.t('Changes are saving and will be lost if you leave. Are you sure you want to leave?');
const teiValidationErrorsMessage = () => i18next.t('You have an error with one or more of your assessment items. Your changes will be lost if you leave. Are you sure you want to leave?');
const notesUnsavedChangesMessage = () => i18next.t('You have unsaved changes in your notes that will be lost if you leave. Are you sure you want to leave?');

const routeWithoutPageId = ({
  path, params, query, hash,
}) => ({
  path, params, query: omit(query, 'page_id'), hash,
});

export const createRouter = (pinia) => {
  const router = createVueRouter({
    history: createWebHistory('/'),
    routes,
  });

  window.onbeforeunload = () => {
    const editorStore = useEditorStore(pinia);
    const assessmentStore = useAssessmentStore(pinia);
    const confirmationStore = useConfirmationStore(pinia);
    if (editorStore.saving || assessmentStore.unsubmittedAttempt) {
      return unsavedChangesMessage();
    }
    if (editorStore.draftHasTEIErrors) return teiValidationErrorsMessage();
    const notesHasUnsavedChanges = confirmationStore[types.GET_CONFIRMATION]('highlight-notes-unsaved-changes');
    if (notesHasUnsavedChanges) {
      return notesUnsavedChangesMessage();
    }
    return undefined;
  };

  router.refreshPageTitle = function refreshPageTitle(to) {
    const route = to || router.currentRoute.value;

    const nearestPageWithTitle = route.matched.slice().reverse().find(
      (r) => r.meta && r.meta.title,
    );

    if (nearestPageWithTitle) {
      document.title = i18next.t('%(nearestPageWithTitle)s | Studio | Discovery Education', {
        nearestPageWithTitle: nearestPageWithTitle.meta.title(),
      });
    } else {
      document.title = i18next.t('Studio | Discovery Education');
    }
  };

  bus.on('refreshPageTitle', router.refreshPageTitle);

  router.beforeEach((to, from, next) => {
    const appStore = useAppStore(pinia);
    const editorStore = useEditorStore(pinia);
    const errorStore = useErrorStore(pinia);
    const messageStore = useMessageStore(pinia);

    appStore[types.SET_STUDIO_REFERER](from);

    const isPageNavigation = isEqual(routeWithoutPageId(to), routeWithoutPageId(from))
      && (from.name === 'edit' || from.name === 'view');

    let navCancelled = false;
    if (editorStore.saving && from.name === 'edit') {
      // Allow page_id to change while editing. If anything else changes show confirm.
      // TODO: Should probably use a modal instead of browser confirm here. Copying
      // from App.vue for now since that's outside scope of current ticket.
      if (!isPageNavigation) {
        // eslint-disable-next-line
        if (!confirm(unsavedChangesMessage())) {
          navCancelled = true;
          next(false);
        }
      }
    }

    /*
      Clear thumbnail loader tracking objects when route changes and
      turn off thumbnail loader tracking.
    */
    editorStore[types.CLEAR_THUMBNAIL_LOADERS]();
    editorStore[types.SET_TRACK_THUMBNAIL_LOADERS](false);

    // Flushes Pinia so error and loading doesn't persist between pages
    errorStore[types.SET_ERROR]({ active: false, error: null });

    // Allow loading spinner to persist while navigating if flag is set.
    if (appStore.allowLoadingNextRouteChange) {
      appStore[types.SET_ALLOW_LOADING_NEXT_ROUTE_CHANGE](false);
    } else {
      appStore[types.UPDATE_LOADING](false);
    }

    if (!isPageNavigation) messageStore[types.SET_APP_MESSAGE](null);

    // If we navigate away from the editor, clear out editor status messages
    if (['view', 'edit'].includes(from.name) && !['view', 'edit'].includes(to.name)) {
      editorStore[types.SET_EDITOR_STATUS_MESSAGE](null);
    }

    // Handle setting errors
    if (!to.matched.length) { // page 404
      const payload = {
        active: true,
        error: {
          meta: {
            message: i18next.t('The link you clicked may be broken or the page may have been removed.'),
            request_id: null,
            status_code: 404,
          },
        },
      };
      errorStore[types.SET_ERROR](payload);
    }

    if (!isPageNavigation) {
      // Update the page title and body class, unless we've navigated between pages
      router.refreshPageTitle(to);

      document.body.className = document.body.className.replace(/studio--[^\s]*/, '');
      document.body.classList.add(`studio--${to.name}`);
    }

    if (!navCancelled) {
      next();
    }
  });

  router.afterEach((to, from) => {
    if (window.DEAnalytics && window.analytics && window.analytics.initialized) {
      // For editor routes, we don't need to track the initial page navigation that adds the
      // page_id query param.
      const isAddPageIdNavigation = isEqual(routeWithoutPageId(to), routeWithoutPageId(from))
        && !from.query.page_id;

      if (isAddPageIdNavigation) return;

      // For all other navigation, send a pageview with Segment
      window.DEAnalytics.ajaxRefreshPageTrack({
        url: `${window.location.origin}${to.fullPath}`,
        previousUrl: `${window.location.origin}${from.fullPath}`,
      });
    }
  });

  return router;
};

export default {
  createRouter,
};
