<template>
  <div class="icon-picker">
    <div
      ref="iconsDropdown"
      class="icon-picker__dropdown"
      v-show="iconsDropdownActive"
    >
      <ItemPicker
        ref="itemPicker"
        :items="nebulaIcons()"
        :selected-item="selectedIcon"
        :search-aria-label="$t('Search icons')"
        @item-selected="$emit('icon-selected', $event)"
      />
    </div>
  </div>
</template>

<script>
import { ItemPicker, nebulaIcons } from '@discoveryedu/titan-text-editor';

export default {
  name: 'IconPicker',
  components: {
    ItemPicker,
  },
  props: {
    iconsDropdownActive: Boolean,
    selectedIcon: {
      type: String,
      default: null,
    },
  },
  emits: ['icon-selected'],
  methods: {
    nebulaIcons,
  },
  watch: {
    iconsDropdownActive() {
      // Move focus to the search input after the dropdown renders
      this.$nextTick(() => {
        if (this.iconsDropdownActive && this.$refs.itemPicker) {
          this.$refs.itemPicker.focus();
        }
      });
    },
  },
};
</script>

<style lang="stylus">
.icon-picker {
  &__dropdown {
    background: $nebula-color-white;
    border-radius: $nebula-border-radius-badge-default;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2), 0px 0px 8px rgba(0, 0, 0, 0.15);
    inset-inline-end: 0;
    max-height: 1000px;
    padding: $nebula-space-quarter;
    position: absolute;
    width: 261px;
    z-index: 160;
  }
}
</style>
