<template>
  <div
    v-if="user"
    class="user-menu comet-product-bar__action comet-product-bar__action--profile"
  >
    <button
      class="user-menu__button comet-button comet-button--small
        comet-product-bar__action-link comet-dropdown-trigger js-comet-dropdown-trigger"
      :aria-expanded="dropdownOpen.toString()"
      @click="toggleDropdown"
    >
      {{ initials }}
    </button>
    <transition
      name="user-menu-dropdown-transition"
      enter-from-class="user-menu-dropdown-transition--enter-from"
      enter-active-class="user-menu-dropdown-transition--enter-active"
      leave-active-class="user-menu-dropdown-transition--leave-active"
      leave-to-class="user-menu-dropdown-transition--leave-to"
    >
      <div
        class="user-menu-dropdown comet-list-group comet-list-group--no-hairlines"
        v-show="dropdownOpen"
        :aria-hidden="!dropdownOpen"
      >
        <ul class="comet-list-group__list">
          <li class="comet-list-group__row">
            <a
              v-if="showProfileUrl"
              class="user-menu-dropdown__link user-menu-dropdown__link--profile
                comet-list-group__row-anchor"
              :href="profileUrl"
            >
              <div class="user-menu-dropdown__link-inner-container">
                <span class="user-menu-dropdown__link-name">
                  {{ user.first_name }} {{ user.last_name }}
                </span>
                <span class="user-menu-dropdown__roles">
                  {{ userEditorialRoles }}
                </span>
                <span class="user-menu-dropdown__link-view-profile">{{ $t('View Profile') }}</span>
              </div>
            </a>
            <div
              v-else
              class="user-menu-dropdown__link"
            >
              <div class="user-menu-dropdown__link-inner-container">
                <span class="user-menu-dropdown__link-name">
                  {{ user.first_name }} {{ user.last_name }}
                </span>
                <span class="user-menu-dropdown__roles">
                  {{ userEditorialRoles }}
                </span>
              </div>
            </div>
          </li>
          <li class="comet-list-group__row">
            <a
              class="user-menu-dropdown__link user-menu-dropdown__link--logout
                comet-list-group__row-anchor"
              :href="logoutUrl"
            >
              <span>{{ $t('Sign Out') }}</span>
            </a>
          </li>
          <li
            v-if="userIsImpersonating"
            class="comet-list-group__row"
          >
            <a
              :href="unimpersonateUrl"
              class="comet-list-group__row-anchor user-menu-dropdown__link"
              :target="unimpersonateTarget"
              :rel="unimpersonateRel"
            >
              <span
                class="comet-list-group__row-label
                comet-list-group__row-label--quit-impersonation"
              >
                <NebulaIcon
                  symbol-id="edit-profile"
                  class="comet-list-group__row-label-icon"
                />
                {{ $t('Quit Impersonation') }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { get } from 'lodash-es';
import { mapState } from 'pinia';
import {
  editorialRoleLabels,
  PRODUCT_SETTING_HIDE_PROFILE,
} from '@/lib/constants';
import {
  useUserStore,
} from '@/stores';

export default {
  name: 'UserMenu',
  components: {
    NebulaIcon,
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapState(useUserStore, [
      'user',
      'domains',
      'productSettings',
      'userIsImpersonating',
      'userIsStudent',
    ]),
    initials() {
      return this.user ? `${this.user.first_name.charAt(0)}${this.user.last_name.charAt(0)}` : null;
    },
    logoutUrl() {
      return `${this.domains.appDomain}/logout`;
    },
    profileUrl() {
      return `${this.domains.appDomain}/profile`;
    },
    showProfileUrl() {
      return !this.userIsStudent && !get(this.productSettings, `${PRODUCT_SETTING_HIDE_PROFILE}.value`);
    },
    unimpersonateUrl() {
      let unimpersonatePath = '/users/unimpersonate';
      if (window.location !== window.parent.location) {
        unimpersonatePath += '?embedded=True';
      }

      return new URL(unimpersonatePath, this.domains.adminDomain).toString();
    },
    unimpersonateRel() {
      return (window.location !== window.parent.location) ? 'opener' : false;
    },
    unimpersonateTarget() {
      return (window.location !== window.parent.location) ? '_blank' : false;
    },
    userEditorialRoles() {
      const roleLabels = editorialRoleLabels();
      const filteredRoleLabels = this.user.roles.reduce((acc, curr) => {
        if (roleLabels[curr.code]) {
          acc.push(roleLabels[curr.code]);
        }

        return acc;
      }, []);

      return filteredRoleLabels.join(', ');
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    onBodyClick({ target }) {
      // Close the dropdown if we click outside of it
      if (!this.$el.contains(target)) {
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    receiveMessage(event) {
      const appDomain = new URL(this.domains.appDomain);

      if (
        event.data
        && typeof event.data === 'string'
        && event.origin === appDomain.origin
      ) {
        const message = JSON.parse(event.data);

        if (message.type === 'unimpersonate' && message.next) {
          setTimeout(() => {
            document.location.href = message.next;
          }, 2000);
        }
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.onBodyClick);

    if (window.location !== window.parent.location) {
      window.addEventListener('message', this.receiveMessage, false);
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onBodyClick);

    if (window.location !== window.parent.location) {
      window.removeEventListener('message', this.receiveMessage, false);
    }
  },
};
</script>

<style lang="stylus">
.user-menu {
  &__button {
    background: transparent;
    border: $comet-border-hairline-default;
    border-radius: 50%;
    color: $comet-color-neutral-80;
    height: 46px;
    margin: 0;
    text-transform: uppercase;
    width: 46px;
  }

  .user-menu-dropdown {
    background: $comet-color-neutral-20;
    border: $comet-border-hairline-default;
    border-radius: $nebula-border-radius-badge-default;
    height: auto;
    inset-inline: auto 0;
    position: absolute;
    top: 60px;
    min-width: 200px;
    z-index: -8;

    .comet-list-group__list {
      background-color: $comet-color-neutral-15;
      border-radius: $nebula-border-radius-badge-default;
      margin: 0;

      .comet-list-group__row {
        background-color: transparent;

        &:hover {
          background: none;
        }

        &:first-child {
          border-top: none;
          border-bottom: $comet-border-hairline-on-dark;
        }
      }
    }

    .user-menu-dropdown__link {
      display: flex;
      color: $comet-text-color-primary-on-dark;
      padding: $nebula-space-1x $nebula-space-2x;
      text-decoration: none;
      transition: background-color $nebula-transition-default;
      width: 100%;

      span {
        flex-grow: 1;
        height: 100%;
        width: 100%;
      }

      .user-menu-dropdown__link-inner-container {
        display: flex;
        flex-direction: column;
      }

      .user-menu-dropdown__roles {
        color: $comet-text-color-secondary-on-dark;
        font-size: $comet-font-size-metadata;
      }

      .user-menu-dropdown__link-view-profile {
        color: $comet-text-color-secondary-on-dark;
        font-size: $nebula-font-size-caption;
        text-decoration: underline;
      }

      &--profile, &--logout {
        &:hover,
        &:focus {
          background: $comet-background-color-dark;
        }
      }
    }
  }

  .user-menu-dropdown-transition--enter-active,
  .user-menu-dropdown-transition--leave-active {
    transition: $nebula-transition-default;
  }

  .user-menu-dropdown-transition--enter-from,
  .user-menu-dropdown-transition--leave-to {
    transform: translateY(-120%);
  }

  .comet-list-group__row-label--quit-impersonation {
      background: #ff7d2a; // custom color to match suite-front-end (not a comet color)
      border-radius: $nebula-border-radius-badge-default;
      color: $nebula-color-white;
      margin: 0;
      padding: $nebula-space-1x;

      svg {
        height: $comet-size-icon-m;
        width: $comet-size-icon-m;
      }
  }

  .comet-list-group__row-label-icon {
      fill: $nebula-color-white;
      margin-inline-end: $nebula-space-half;
      vertical-align: middle;
  }

  html[dir="rtl"] & {
    &__button {
      margin: 0;
    }

    .comet-list-group__row-label--quit-impersonation {
      margin: 0;
    }
  }
}
</style>
