import { get } from 'lodash-es';
import { mapActions, mapState } from 'pinia';
import { isIframe } from '@/lib/utils';
import {
  useEditorStore,
  useModalStore,
  useUserStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  computed: {
    ...mapState(useEditorStore, [
      'draftAssetId',
      'domains',
      'draft',
    ]),
    ...mapState(useUserStore, [
      'userCanAssign',
      'userDataIsLoaded',
    ]),
    showAssignButton() {
      return this.userDataIsLoaded
      && this.userCanAssign
      && !this.isGrading
      && !isIframe()
      && this.mode === 'VIEW';
    },
    isGrading() {
      const { source } = get(this, '$route.query', {});
      // account for 'old' source qsp that may be present with the new source urls
      if (Array.isArray(source)) {
        return source.includes('grade_assignment');
      }
      return source === 'grade_assignment';
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.SET_DRAFT_IS_DIRTY,
      types.PUBLISH_DRAFT,
    ]),
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
    ]),
    openSubmitAssignmentModal() {
      this[types.OPEN_MODAL]({
        type: 'SubmitAssignmentModal',
      });
    },
    openConfirmAssignModal() {
      const getAssignUrl = (creation) => {
        const host = this.domains.appDomain;
        const assetId = this.draftAssetId(creation);

        const qsp = new URLSearchParams(window.location.search);
        qsp.set('disable_back_button', 1);

        const backUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}?${qsp.toString()}`);
        return `${host}/assign/${assetId}?back=${backUrl}`;
      };

      this[types.OPEN_MODAL]({
        type: 'ConfirmAssignModal',
        onContinue: async () => {
          if (this.mode === 'EDIT') {
            this[types.SET_DRAFT_IS_DIRTY](false);
            const creation = await this[types.PUBLISH_DRAFT]({ draftId: this.draft.id });
            window.location.href = getAssignUrl(creation);
          } else {
            window.location.href = getAssignUrl();
          }
        },
      });
    },
  },
};
