<template>
  <div class="bulk-page-listing-message">
    <p class="bulk-page-listing-message__preview">
      {{ label }}
    </p>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import {
  useBulkActionsStore,
  useEditorStore,
} from '@/stores';

export default {
  name: 'BulkPageListingMessage',
  computed: {
    ...mapState(useEditorStore, [
      'draftType',
    ]),
    ...mapState(useBulkActionsStore, [
      'bulkActionPageIds',
    ]),
    label() {
      return this.draftType === 'board'
        ? this.$t('%(count)d Page', { count: this.bulkActionPageIds.length })
        : this.$t('%(count)d Slide', { count: this.bulkActionPageIds.length });
    },
  },
};
</script>

<style lang="stylus">
$preview-height = 84px;
$preview-width = 134px;

.bulk-page-listing-message {
  padding: 2px 2px 0;

  &__preview {
    // Match the styles used in the normal page listing preview
    align-items: center;
    background-color: $nebula-color-interface-blue-400;
    border-radius: $nebula-border-radius-small;
    color: $nebula-color-white;
    display: flex;
    font-weight: 600;
    height: $preview-height;
    justify-content: center;
    margin: 0 0 38px; // match the height of the title input
    width: $preview-width;
  }
}
</style>
