<template>
  <BaseModal
    :id="id"
    is-wide
    :heading="$t('Print Layout')"
    hide-footer
    :initial-focus="() => $refs.printLink"
  >
    <div class="print-layout-modal">
      <div class="print-layout-modal__column">
        <a
          ref="printLink"
          class="print-layout-modal__link"
          target="_blank"
          :href="printPreviewLocation()"
          @click="close()"
        >
          <img
            class="print-layout-modal__image"
            src="@/assets/disco-print-slides-only.png"
            alt=""
          >
          <div class="print-layout-modal__link-content">
            <NebulaIcon
              class="print-layout-modal__link-content-item"
              symbol-id="page"
              size="l"
            />
            <div
              class="print-layout-modal__link-content-item
                print-layout-modal__link-content-item--grow
                print-layout-modal__link-content-item--margin"
            >
              <div class="print-layout-modal__link-content-item-title">
                {{ printTitle }}
              </div>
              <div class="print-layout-modal__link-content-item-text">
                {{ printText }}
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="print-layout-modal__column">
        <a
          class="print-layout-modal__link"
          target="_blank"
          :href="printPreviewLocation(true)"
          @click="close()"
        >
          <img
            class="print-layout-modal__image"
            src="@/assets/disco-print-with-notes.png"
            alt=""
          >
          <div class="print-layout-modal__link-content">
            <NebulaIcon
              class="print-layout-modal__link-content-item"
              symbol-id="notes"
              size="l"
            />
            <div
              class="print-layout-modal__link-content-item
                print-layout-modal__link-content-item--grow
                print-layout-modal__link-content-item--margin"
            >
              <div class="print-layout-modal__link-content-item-title">
                {{ $t('Notes Pages') }}
              </div>
              <div class="print-layout-modal__link-content-item-text">
                {{ printWithNotesText }}
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { NebulaIcon } from '@discoveryedu/nebula-components';
import BaseModal from '@/components/modals/BaseModal.vue';
import * as types from '@/lib/constants/store';
import {
  useEditorStore,
  useModalStore,
} from '@/stores';

export default {
  name: 'PrintLayoutModal',
  components: {
    BaseModal,
    NebulaIcon,
  },
  props: {
    id: {
      type: String, // used for opening and closing modal
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState(useEditorStore, [
      'domains',
      'draftPointerAssetId',
      'draftType',
    ]),
    hasSlides() {
      return this.draftType !== 'board';
    },
    printTitle() {
      return this.hasSlides
        ? this.$t('Full Page Slides')
        : this.$t('Full Pages');
    },
    printText() {
      return this.hasSlides
        ? this.$t('Print Slides without Teacher Notes')
        : this.$t('Print Pages without Teacher Notes');
    },
    printWithNotesText() {
      return this.hasSlides
        ? this.$t('Print Slides with Teacher Notes included')
        : this.$t('Print Pages with Teacher Notes included');
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    close() {
      this[types.CLOSE_MODAL]({ id: this.id });
    },
    printPreviewLocation(printNotes) {
      const assetId = this.draftPointerAssetId() || this.options?.assetId;
      if (!assetId) return null;

      const type = (this.$route?.name === 'edit' || this.options?.embedded)
        ? 'draft'
        : 'creation';

      let url = `${this.domains.studioDomain}/print-preview?id=${assetId}&type=${type}`;

      if (printNotes) {
        url += '&printNotes=true';
      }

      return url;
    },
  },
};
</script>

<style lang="stylus">
.print-layout-modal {
  display: flex;
  max-width: 698px;

  &__column {
    width: 50%;

    &:not(:first-of-type) {
      margin-left: $nebula-space-1x;
    }
  }

  &__link {
    display: block;

    &:link, &:active, &:visited, &:focus {
      text-decoration: none;
    }

    &:hover {
      .print-layout-modal__link-content {
        background-color: $nebula-color-platform-interactive-200;
      }

      &:focus-visible {
        outline: auto;
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    &:active {
      .print-layout-modal__link-content {
        background-color: $nebula-color-platform-interactive-400;
      }
    }
  }

  &__link-content {
    border-radius: $nebula-border-radius-small;
    display: flex;
    padding: $nebula-space-2x;

    svg {
      fill: $nebula-color-platform-interactive-900;
    }
  }

  &__link-content-item {
    &--margin {
      margin-inline-start: $nebula-space-2x;
    }

    &--grow {
      flex: 1 1 auto;
    }

    &-title {
      nebula-text-h6();
      color: $nebula-color-platform-interactive-900;
      font-size: $nebula-font-size-mobile-header-level-6;
      margin-bottom: $nebula-space-1x;
    }

    &-text {
      color: $nebula-color-platform-neutral-800;
      font-size: $nebula-font-size-caption;
    }
  }

  &__image {
    padding-inline-end: $nebula-space-3x + $nebula-space-half;
    padding-inline-start: $nebula-space-3x + $nebula-space-half;
    width: 100%;
  }
}
</style>
