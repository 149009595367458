<template>
  <div class="tei-message-banner">
    <div class="tei-message-banner__text-container">
      <NebulaIcon
        class="tei-message-banner__icon"
        symbolId="information"
      />
      <p class="tei-message-banner__text">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
  name: 'TEIMessageBanner',
  components: {
    NebulaIcon,
  },
  data() {},
  computed: {
    message() {
      return this.$t('Editing permissions are limited on assessment slides.');
    },
  },
};
</script>

<style lang="stylus">
.tei-message-banner {
  position: sticky;
  top: $nebula-space-7x;
  z-index: -10;

  // Let banner scroll with content on extra small screen sizes
  @media (max-width: $nebula-breakpoints-mobile-landscape) {
    position: static;
  }

  align-items: center;
  background-color: $nebula-color-feedback-warning-100;
  color: $nebula-color-feedback-warning-700;
  font-size: $nebula-font-size-caption;
  display: flex;
  flex-grow: auto;
  justify-content: space-between;
  padding: 0px $nebula-space-2x;

  @media (min-width: $nebula-breakpoints-mobile-landscape) {
    font-size: $nebula-font-size-body-2;
  }

  &__text-container {
    align-items: center;
    display: flex;
    gap: $nebula-space-1x;
  }

  &__text {
    font-weight: 600;
  }

  &__icon {
    fill: $nebula-color-feedback-warning-700;
  }

  &__button {
    --nebula-button-background-active: $nebula-color-feedback-warning-900;
    --nebula-button-background-hover: $nebula-color-feedback-warning-800;
    --nebula-button-border-color-active: $nebula-color-feedback-warning-900;
    --nebula-button-border-color-hover: $nebula-color-feedback-warning-800;
    --nebula-button-border-color: $nebula-color-feedback-warning-700;
    --nebula-button-font-size: $nebula-font-size-caption;
    --nebula-button-text-color: $nebula-color-feedback-warning-700;

    @media (min-width: $nebula-breakpoints-mobile-landscape) {
      --nebula-button-font-size: $nebula-font-size-body-2;
    }
  }
}
</style>
