import { isEqual } from 'lodash-es';
import { mapState, mapActions } from 'pinia';
import {
  useEditorStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  props: {
    url: Object,
  },
  data() {
    return {
      validThumbnailUrl: null,
      thumbnailIsLoading: false,
    };
  },
  created() {
    this.tryLoadThumbnail();
  },
  computed: {
    ...mapState(useEditorStore, [
      'urlRequestCache',
      'trackThumbnailLoaders',
    ]),
    hasImageUrls() {
      return !!this.imageUrls?.length;
    },
    imageUrls() {
      // The zoom modal always uses large images, but responsive modules use
      // the normal "imageUrls" array
      return this.useLargeImageUrls
        ? this.urlOptions.largeImageUrls || this.urlOptions.imageUrls
        : this.urlOptions.imageUrls;
    },
    thumbnailLoaderId() {
      return this.urlOptions.thumbnailLoaderId;
    },
    urlOptions() {
      // Modules have url passed in as a prop; the zoom modal has url
      // in its options prop instead; the asset mixin provides this as assetThumbnailUrl
      return this.url || this.options?.url || this.assetThumbnailUrl || {};
    },
  },
  watch: {
    urlOptions() {
      this.tryLoadThumbnail();
    },
  },
  methods: {
    ...mapActions(useEditorStore, [
      types.CACHE_URL_REQUEST,
      types.TRACK_THUMBNAIL_LOADER,
    ]),
    async tryLoadThumbnail() {
      if (this.thumbnailLoaderDisabled) return;
      /*
        If this asset is already trying to load a thumbnail, or
        if there are no thumbnails to load, then exit.
      */
      if (!this.hasImageUrls || this.thumbnailIsLoading) return;
      const { imageUrls } = this;

      const runThumbnailLoader = async () => {
        this.thumbnailIsLoading = true;
        this.validThumbnailUrl = null;
        /*
          Attempt to load all possible thumbnails for this asset
          and stop after we find one that exists.
        */
        for (let index = 0; index < imageUrls.length; index += 1) {
          const { url, isThumbnail } = imageUrls[index];

          /*
            For each thumbnail url, store a promise in the app store that
            determines if the image exists (resolved) or not (rejected).
            A promise for a thumbnail is shared for all assets and pages and
            multiple requests for the same thumbnail will share this single promise
            in order to reduce the number of total requests.
          */
          let promise = this.urlRequestCache[url];
          if (!promise) {
            if (isThumbnail) {
              /*
                If this is a thumbnail image, we need to handle differently by
                making a head request to check if the image exists. We can't check
                by loading the image itself because the server returns a default
                image when the status is 404 not found.
              */
              promise = this.$api.head(`${url}?cacheline=corshead`, {
                withCredentials: false,
              }).then((result) => {
                if (result.status !== 200) throw result;
              });
            } else {
              /*
                Otherwise, if this is not a thumbnail image, then we will attempt to
                load the image itself. We can't make a head request here since CORS
                headers are not set for these urls.
              */
              promise = new Promise((resolve, reject) => {
                const thumbImage = new Image();
                thumbImage.onload = () => resolve(true);
                thumbImage.onerror = () => reject(new Error('Image failed to load'));
                thumbImage.src = url;
              });
            }
            this[types.CACHE_URL_REQUEST]({ url, promise });
          }

          try {
            // eslint-disable-next-line no-await-in-loop
            await promise;
            /*
              If the promise resolves, then the thumbnail was found on the server.
              If the resolved thumbnail is different than the currently displayed
              thumbnail url, then update and exit the loop.
            */
            if (url !== this.validThumbnailUrl) {
              this.validThumbnailUrl = url;
            }
            break;
          } catch (error) {
            // Continue to load the next thumbnail if there is one.
          }
        }

        this.thumbnailIsLoading = false;
      };

      const thumbnailLoaderPromise = runThumbnailLoader();

      if (this.trackThumbnailLoaders) {
        this[types.TRACK_THUMBNAIL_LOADER]({
          id: this.thumbnailLoaderId,
          promise: thumbnailLoaderPromise,
        });
      }

      await thumbnailLoaderPromise;

      /*
        If the list of thumbnail urls changed (e.g. module resized) while we were
        attempting to load a thumbnail, then we need to run the thumbnail loader
        again to update to the appropriate thumbnail url.
      */
      if (!this.hasImageUrls) {
        this.validThumbnailUrl = null;
      } else {
        const urlHasChanged = this.imageUrls.length !== imageUrls.length
          || this.imageUrls.some((imageUrl, idx) => !isEqual(imageUrl, imageUrls[idx]));
        if (urlHasChanged) {
          this.tryLoadThumbnail();
        }
      }
    },
  },
};
