/* This mixin expects the component to have an `asset` computed property */

import { mapActions, mapState } from 'pinia';
import {
  useModalStore,
  useQuicklistStore,
  useChannelStore,
  useUserStore,
} from '@/stores';
import * as types from '@/lib/constants/store';

export default {
  emits: ['set-previous-focus'],
  computed: {
    ...mapState(useUserStore, [
      'userIsStudent',
      'region',
      'domains',
    ]),
    ...mapState(useChannelStore, [
      'channelIds',
    ]),
    ...mapState(useQuicklistStore, [
      'quicklistIds',
    ]),
    actionMenuProps() {
      return {
        asset: this.asset,
        assetType: this.asset?.type?.name,
        backUrl: window.location.href,
        learnUrl: this.domains.appDomain,
        showCreateWithStudio: false,
        userIsStudent: this.userIsStudent,
        quicklistIds: this.quicklistIds,
        subscribedChannelIds: this.channelIds,
      };
    },
    useCoreQuicklist() {
      // This property won't be reactive, but it is a cheap
      // hack to see if we are in core or not.
      return window.initData && window.location.href.includes('/learn');
    },
  },
  methods: {
    ...mapActions(useModalStore, [
      types.OPEN_MODAL,
      types.SET_MY_CONTENT_MODAL_OPTIONS,
    ]),
    ...mapActions(useQuicklistStore, [
      types.ADD_TO_QUICKLIST,
      types.REMOVE_FROM_QUICKLIST,
    ]),
    ...mapActions(useChannelStore, [
      types.SUBSCRIBE_TO_CHANNEL,
      types.UNSUBSCRIBE_FROM_CHANNEL,
    ]),
    onAddToQuicklist() {
      if (this.useCoreQuicklist) {
        // In Core, let Marionette handle the QL
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: 'quicklist:add',
            asset: this.asset,
          },
        }));
        return;
      }

      this[types.ADD_TO_QUICKLIST](this.asset);
    },
    async onRemoveFromQuicklist() {
      if (this.useCoreQuicklist) {
        // In Core, let Marionette handle the QL
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: 'quicklist:remove',
            asset: this.asset,
          },
        }));
        return;
      }

      await this[types.REMOVE_FROM_QUICKLIST](this.asset);
      this.$emit('remove-from-quicklist', this.asset.id);
    },
    openMyContentModal() {
      this.$emit('set-previous-focus', this.$el.querySelector('.nebula-card__action-menu .nebula-button'));

      this[types.SET_MY_CONTENT_MODAL_OPTIONS]({
        asset: {
          actions: [],
          selectedAssetId: this.asset.id,
          selectedAssetTitle: this.asset.title || this.asset.name,
          sharingEnabled: true,
        },
        config: {
          hideDistrictTab: this.region === 'UK', // see DEX-2646
          isActive: true,
          isStudent: this.userIsStudent,
          modalType: 'save',
        },
      });
    },
    openShareModal() {
      this.$emit('set-previous-focus', this.$el.querySelector('.nebula-card__action-menu .nebula-button'));

      this[types.OPEN_MODAL]({
        type: 'AssetShareModal',
        id: 'AssetShareModal',
        assetUrl: `${this.domains.appDomain}/player/${this.asset.id}`,
        assetGuid: this.asset.id,
        headline: this.asset.name || '',
        assetDescription: this.asset.description || '',
      });
    },
    subscribe() {
      // In Core, let Marionette handle My Channels
      if (this.useCoreQuicklist) {
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: 'subscribe',
            asset: this.asset,
          },
        }));
        return;
      }

      this[types.SUBSCRIBE_TO_CHANNEL](this.asset);
    },
    unsubscribe() {
      // In Core, let Marionette handle My Channels
      if (this.useCoreQuicklist) {
        document.dispatchEvent(new CustomEvent('actionMenu', {
          detail: {
            type: 'unsubscribe',
            asset: this.asset,
          },
        }));
        return;
      }

      this[types.UNSUBSCRIBE_FROM_CHANNEL](this.asset);
    },
  },
};
