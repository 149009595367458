<template>
  <div class="comet-dropdown">
    <button
      ref="button"
      :aria-label="buttonAriaLabel"
      data-click-name="Open Dropdown Menu"
      data-click-type="Studio Top Navigation"
      :data-click-experience="experimentCopyJan"
      :data-click-asset-id="assetId"
      class="comet-button
        comet-button--primary
        comet-dropdown-trigger
        comet-button--icon-with-text"
      :class="[{
        'comet-dropdown-trigger--menu-visible': dropdownOpen,
        'comet-button--active comet-button--hover': dropdownOpen,
      }].concat(buttonClass)"
      aria-haspopup="true"
      @click="toggleDropdown"
    >
      <slot name="button">
        <span class="comet-button__inner">
          <span class="comet-button__text">
            {{ selectedItem ? selectedItem.label : '' }}
          </span>
          <NebulaIcon
            symbol-id="caret-down"
            class="comet-button__icon comet-button__icon--right"
            size="m"
          />
        </span>
      </slot>
    </button>
    <transition>
      <div
        ref="menu"
        class="comet-dropdown-menu comet-popover comet-popover--bottom-left-aligned"
        :class="[{
          visible: dropdownOpen,
          'comet-dropdown-menu--calculated-menu-position': menuPositionCalculated,
        }].concat(menuClass)"
        v-show="dropdownOpen"
        :aria-hidden="!dropdownOpen"
      >
        <slot name="items">
          <div
            class="comet-list-group comet-dropdown-menu__list-group
              comet-list-group--no-hairlines"
          >
            <ul class="comet-list-group__list">
              <li
                class="comet-list-group__row"
                :class="{
                  'comet-list-group__row--selected': item.selected && !selectedIcon,
                }"
                v-for="item in localItems"
                :key="item.label"
              >
                <component
                  :is="item.href ? 'a' : 'button'"
                  :href="item.href"
                  class="comet-list-group__row-anchor"
                  @click="select(item)"
                >
                  <NebulaIcon
                    v-if="selectedIcon && item.selected"
                    :symbol-id="selectedIcon"
                    class="comet-list-group__row-type-icon"
                    size="s"
                  />
                  <NebulaIcon
                    v-if="item.icon"
                    :symbol-id="item.icon"
                    class="comet-list-group__row-type-icon"
                    size="s"
                  />
                  <span class="comet-list-group__row-label">
                    {{ item.label }}
                  </span>
                </component>
              </li>
            </ul>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { NebulaIcon } from '@discoveryedu/nebula-components';
import { throttle } from 'lodash-es';
import { bus } from '@/lib/eventBus';
import experimentData from '@/mixins/experimentData';
import {
  useEditorStore,
} from '@/stores';

export default {
  name: 'CometDropdown',
  components: {
    NebulaIcon,
  },
  emits: [
    'dropdown-open',
    'dropdown-select',
  ],
  computed: {
    ...mapState(useEditorStore, [
      'draftPointerAssetId',
    ]),
    selectedItem() {
      return this.localItems.find((item) => item.selected);
    },
    experimentCopyJan() {
      return JSON.stringify(this.getExperimentData('variant-1-studio-copy-experiment-jan-23'));
    },
    assetId() {
      return this.draftPointerAssetId();
    },
  },
  mixins: [experimentData],
  data() {
    return {
      dropdownOpen: false,
      localItems: this.items,
    };
  },
  watch: {
    items() {
      this.localItems = this.items;
    },
    dropdownOpen() {
      this.$emit('dropdown-open', this.dropdownOpen);
      this.toggleMenuPositionHandlers();
      this.$nextTick(this.updateMenuPosition);
    },
  },
  methods: {
    closeDropdown() {
      this.dropdownOpen = false;
    },
    onBodyClick({ target }) {
      // Close the dropdown if we click outside of it
      if (!this.$el.contains(target)) {
        this.closeDropdown();
      }
    },
    openDropdown() {
      this.dropdownOpen = true;
    },
    select(newItem) {
      if (newItem.onClick) {
        newItem.onClick();
        this.closeDropdown();
        return;
      }

      this.localItems = this.localItems.map((item) => ({
        ...item,
        selected: item === newItem,
      }));
      this.$emit('dropdown-select', this.selectedItem);
      this.closeDropdown();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    updateMenuPosition() {
      if (this.dropdownOpen && this.menuPositionCalculated) {
        const buttonRect = this.$refs.button.getBoundingClientRect();
        const menuRect = this.$refs.menu.getBoundingClientRect();
        const viewportWidth = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0,
        );
        const viewportHeight = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0,
        );

        /*
          Attempt to keep the menu visible as much as possible
          both horizontally and vertically.
        */
        let menuLeft = buttonRect.left;
        const menuRight = menuLeft + menuRect.width;
        if (menuRight > viewportWidth) menuLeft -= menuRight - viewportWidth;
        else if (menuLeft < 0) menuLeft = 0;
        this.$refs.menu.style.left = `${menuLeft}px`;

        let menuTop = buttonRect.bottom;
        if (menuTop + menuRect.height > viewportHeight) {
          const adjustedMenuTop = buttonRect.top - menuRect.height;
          if (adjustedMenuTop > 0) menuTop = adjustedMenuTop;
        }
        this.$refs.menu.style.top = `${menuTop}px`;
      }
    },
    throttleUpdateMenuPosition: throttle(function throttleUpdateMenuPosition() {
      this.updateMenuPosition();
    }, 10),
    toggleMenuPositionHandlers() {
      if (!this.menuPositionCalculated) return;
      if (this.dropdownOpen) {
        bus.on('pageList:scroll', this.updateMenuPosition);
        window.addEventListener('resize', this.throttleUpdateMenuPosition);
        window.addEventListener('scroll', this.throttleUpdateMenuPosition);
      } else {
        this.removeMenuPositionHandlers();
      }
    },
    removeMenuPositionHandlers() {
      bus.off('pageList:scroll', this.updateMenuPosition);
      window.removeEventListener('resize', this.throttleUpdateMenuPosition);
      window.removeEventListener('scroll', this.throttleUpdateMenuPosition);
    },
  },
  props: {
    items: {
      default() {
        return [];
      },
      type: Array,
    },
    buttonClass: {
      type: String,
      default: null,
    },
    buttonAriaLabel: {
      type: String,
      default: null,
    },
    menuClass: {
      type: String,
      default: null,
    },
    selectedIcon: {
      type: String,
      default: null,
    },
    menuPositionCalculated: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    bus.on('document:click', this.onBodyClick);
  },
  beforeUnmount() {
    bus.off('document:click', this.onBodyClick);
    this.removeMenuPositionHandlers();
  },
};
</script>

<style lang="stylus" scoped>
.comet-list-group__row-anchor {
  background: none;
  border: none;
  text-align: left;
}

.comet-list-group__row-type-icon {
  height: $nebula-space-2x;
  width: $nebula-space-2x;
}

.comet-dropdown-menu--calculated-menu-position {
  position: fixed;
  z-index: 601;
}
</style>
