<template>
  <div
    role="dialog"
    aria-modal="true"
    class="modal--base modal--studio"
    :class="[
      {
        'modal--studio-fixed': isFixed,
      }]"
  >
    <!--
      This is missing the .modal class on purpose, since it conflicts with
      old bootstrap styles in core
    -->
    <div
      class="comet-modal-overlay js-comet-modal-overlay comet-modal-overlay--backdrop"
      role="dialog"
      @click="onBackdropClick"
    />
    <FocusTrap
      ref="focusTrap"
      :active="focusTrapActive"
      :initial-focus="initialFocus ||
        (() => $refs.confirmButton || $refs.cancelButton || $refs.headerCancelButton)"
      :fallback-focus="fallbackFocus || initialFocus ||
        (() => $refs.confirmButton || $refs.cancelButton || $refs.headerCancelButton)"
      @deactivate="onCloseModal"
    >
      <div class="comet-modal js-comet-modal">
        <div
          :class="{
            'comet-modal--directional': isDirectional,
            'comet-modal--wide': isWide,
          }"
          class="comet-modal__inner modal__inner"
        >
          <div
            :class="`modal__header--margin-${headerMargin}`"
            class="modal__header comet-modal__content comet-long-form-text"
            v-if="heading"
          >
            <h3 class="modal__heading">
              {{ heading }}
            </h3>
            <button
              ref="headerCancelButton"
              :aria-label="$t('Close')"
              class="nebula-button nebula-button--s nebula-button--icon-only
                modal__header-close-button"
              :disabled="disableCancelButton"
              v-if="!hideHeaderCancelButton"
              @click="headerCancel"
              @focus="onFocus('.modal__header-close-button')"
            >
              <NebulaIcon
                size="m"
                symbol-id="x"
              />
            </button>
          </div>
          <slot name="alert" />
          <slot />
          <slot name="footer">
            <div
              :class="`modal__footer--margin-${footerMargin}`"
              class="comet-modal__footer"
              v-if="!hideFooter"
            >
              <div class="comet-modal__button-row">
                <button
                  ref="cancelButton"
                  :class="[
                    'nebula-button',
                    'nebula-button--s',
                    'nebula-button--ghost',
                    'comet-modal__button',
                    'comet-modal__button--cancel',
                    'js-comet-modal__close-button',
                    cancelClass,
                  ].concat({ 'nebula-button--disabled': disableCancelButton })"
                  :disabled="disableCancelButton"
                  @click="cancel"
                  v-if="!hideCancelButton"
                >
                  {{ cancelText || $t('Cancel') }}
                </button>
                <button
                  ref="confirmButton"
                  class="nebula-button nebula-button--s nebula-button--fill comet-modal__button"
                  :class="[
                    {
                      'nebula-button--disabled': disableConfirmButton,
                    },
                    confirmClass,
                  ]"
                  :disabled="disableConfirmButton"
                  role="button"
                  @click="onConfirm"
                >
                  {{ confirmText || $t('Confirm') }}
                </button>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </FocusTrap>
  </div>
</template>

<script>
import { FocusTrap } from 'focus-trap-vue';
import { mapActions } from 'pinia';
import { NebulaIcon } from '@discoveryedu/nebula-components';
import * as types from '@/lib/constants/store';
import {
  useModalStore,
} from '@/stores';

export default {
  name: 'BaseModal',
  components: {
    FocusTrap,
    NebulaIcon,
  },
  methods: {
    ...mapActions(useModalStore, [
      types.CLOSE_MODAL,
    ]),
    cancel() {
      if (this.onCancel) this.onCancel();
      else this.$refs.focusTrap.deactivate();
    },
    headerCancel() {
      if (this.onHeaderCancel) this.onHeaderCancel();
      else this.cancel();
    },
    onCloseModal() {
      if (this.onClose) this.onClose();
      else this[types.CLOSE_MODAL]({ id: this.id });
    },
    onBackdropClick() {
      if (!this.closeOnBackdropClick) return;
      this.$refs.focusTrap.deactivate();
    },
    onFocus(cssClass) {
      // resolve focus issues on Windows browsers
      const element = document.querySelector(cssClass);
      if (element) element.focus();
    },
  },
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    focusTrapActive: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      required: true,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    onHeaderCancel: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
    onConfirm: {
      type: Function,
      default() {},
    },
    cancelClass: {
      type: [String, Object],
      default: '',
    },
    cancelText: {
      type: String,
      default: null,
    },
    confirmText: {
      type: String,
      default: null,
    },
    confirmClass: {
      type: [String, Object],
      default: '',
    },
    disableConfirmButton: {
      type: Boolean,
      default: false,
    },
    disableCancelButton: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
    hideCancelButton: {
      type: Boolean,
      default: false,
    },
    hideHeaderCancelButton: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    initialFocus: {
      type: Function,
      default: null,
    },
    fallbackFocus: {
      type: Function,
      default: null,
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    isDirectional: {
      type: Boolean,
      default: false,
    },
    isWide: {
      type: Boolean,
      default: true,
    },
    headerMargin: {
      type: String,
      default: '3x',
    },
    footerMargin: {
      type: String,
      default: '3x',
    },
  },
};
</script>

<style lang="stylus">
.comet-modal-overlay {
  z-index: 800;
}

.modal--studio {
  position: relative;

  &-fixed {
    position: fixed;
  }

  .modal__heading:before {
    margin: 0; // prevent vertical clipping
  }

  .modal__heading {
    font-size: $nebula-font-size-body-1;
    font-weight: bold;
    margin: 0 0 $nebula-space-half 0;
  }

  .modal__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .modal__header-close-button {
    background-color: transparent;
    padding: 0;

    &:hover, &:focus {
      background-color: transparent;
    }

    svg {
      fill: $nebula-color-interface-blue-400;
    }
  }

  .comet-modal__content {
    overflow: inherit;
  }

  .comet-modal__button-row {
    gap: $nebula-space-2x;
  }

  .comet-modal__footer {
    .nebula-button {
      justify-content: center;
      width: 100%;

      @media (min-width: $comet-breakpoints-tablet-narrow-portrait) {
        width: max-content;
      }
    }
  }

  .comet-modal__button {
    margin-inline: 0;

    &:first-child {
      margin-inline-start: auto;
    }
  }

  .comet-modal--directional .comet-modal__button:first-child {
    margin-inline: 0 auto;
  }

  for num in (0..5) {
    .modal__footer--margin-{num}x {
      margin-top: num != 0 ? lookup('$nebula-space-' + num + 'x') : 0;
    }

    .modal__header--margin-{num}x {
      margin-bottom: num != 0 ? lookup('$nebula-space-' + num + 'x') : 0;
    }
  }
}
</style>
