<template>
  <div
    class="collaborative-editable"
    :style="currentlyEditingUser && !isCurrentConnectedUser
      ? { border: `2px solid ${currentlyEditingColor}` }
      : null"
  >
    <div
      v-if="currentlyEditingUser && !isCurrentConnectedUser"
      :style="{ background: currentlyEditingColor }"
      class="collaborative-editable__currently-editing"
      :class="{ 'collaborative-editable__currently-editing--inset': inset }"
    >
      <span
        class="collaborative-editable__currently-editing-display-name
          collaborative-editable__currently-editing-display-name--initials"
      >
        {{ currentlyEditingInitials }}
      </span>
      <span
        class="collaborative-editable__currently-editing-display-name
          collaborative-editable__currently-editing-display-name--full-name"
      >
        {{ currentlyEditingName }}
      </span>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import {
  useEditorStore,
  useUserStore,
} from '@/stores';

export default {
  props: {
    inputName: {
      type: String,
      required: true,
    },
    inset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayFullName: false,
    };
  },
  computed: {
    ...mapState(useEditorStore, [
      'currentlyEditing',
      'connectedUsersColors',
    ]),
    ...mapState(useUserStore, [
      'user',
    ]),
    currentlyEditingUser() {
      return this.currentlyEditing(this.inputName);
    },
    isCurrentConnectedUser() {
      return this.currentlyEditingUser?.isCurrentUser;
    },
    currentlyEditingName() {
      return `${this.currentlyEditingUser.firstName} ${this.currentlyEditingUser.lastName}`;
    },
    currentlyEditingInitials() {
      return `${this.currentlyEditingUser.firstName.charAt(0)}${this.currentlyEditingUser.lastName.charAt(0)}`;
    },
    currentlyEditingColor() {
      return this.currentlyEditingUser ? this.connectedUsersColors[this.currentlyEditingUser.clientId] : 'transparent';
    },
  },
};
</script>

<style lang="stylus">
.collaborative-editable {

  &__currently-editing {
    align-items: center;
    border-radius: 50%;
    color: $comet-text-color-primary-on-dark;
    cursor: default;
    display: flex;
    font-size: $nebula-font-size-caption;
    height: 25px;
    justify-content: center;
    left: -15px;
    line-height: 1;
    padding: $nebula-space-half;
    position: absolute;
    top: -15px;
    width: 25px;
    z-index: 99;
  }

  &__currently-editing--inset {
    left: 0;
    top: 0;
  }

  &__currently-editing-display-name--full-name {
    display: none;
  }

  &__currently-editing:hover {
    border-radius: 16px;
    width: auto;

    .collaborative-editable__currently-editing-display-name--initials {
      display: none;
    }

    .collaborative-editable__currently-editing-display-name--full-name {
      display: block;
    }
  }
}
</style>
